import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input, UncontrolledTooltip} from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { surgeonActions, bookingActions } from "../../../../actions";
import { ReactComponent  as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import Select from "react-select";
import DeleteBox from "./Delete";
import AddBox from "./Add";
import Comment from "./Comments/Index";
import { surgeonService } from "../../../../services";
import { ExportSheet } from "react-xlsx-sheet";
import * as XLSX from "xlsx";

const head = [
    { title: "SURGEON NAME", dataIndex: "surgeon_name" },
    { title: "LEVEL", dataIndex: "level" },
    { title: "AREA OF SPECIALITY", dataIndex: "area_of_speciality" },
];
const titleOption=[
    {value:"Mr",label:"Mr"},
    {value:"Mrs",label:"Mrs"},
    {value:"Ms",label:"Ms"},
    {value:"Dr",label:"Dr"}
];

const levelOption=[
    {value:"Consultant",label:"Consultant"},
    {value:"Fellow",label:"Fellow"},
    {value:"Registrar",label:"Registrar"},
    {value:"Dr",label:"Dr"},
    {value:"Other",label:"Other"}
]; 

const specialityOption=[
    {value:"Foot and Ankle",label:"Foot and Ankle"},
    {value:"Hand and Wrist",label:"Hand and Wrist"},
    {value:"Knee",label:"Knee"},
    {value:"Shoulder",label:"Shoulder"},
    {value:"Trauma",label:"Trauma"},
    {value:"Other",label:"Other"}
];

class Index extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            addNewModal: false,
            editModal:false,
            deleteModal:false,
            name: "",
            id:"",
            surgeonInfo: {
                id:"",
                title:"",
                first_name:"",
                last_name:"",
                level:"",
                area_of_speciality:"",
                phone:"",
                mobile:"",
                work_email:"",
                personal_email:"",
                hospitalData:[],
                notes:""
            },
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 1,
            selectedTitle:[],
            selectedLevel:[],
            selectedSpeciality:[],
            selectedHospital:[],
            hospitalOption:[],
            submitModalFalg:0,
            surgeonId:0,
            commentModal:false,
            suggestionSurgeonList:[],
            suggestionsActive:false,
            dataXlsx: [],
        };

        this.getList = this.getList.bind(this);
        this.addNewModalOpen = this.addNewModalOpen.bind(this);
        this.addNewModalClose = this.addNewModalClose.bind(this);
        this.editModalOpen = this.editModalOpen.bind(this);
        this.editModalClose = this.editModalClose.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.deleteModalOpen = this.deleteModalOpen.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.setSelectedOptionTitle = this.setSelectedOptionTitle.bind(this);
        this.setSelectedOptionLevel = this.setSelectedOptionLevel.bind(this);
        this.setSelectedOptionSpeciality = this.setSelectedOptionSpeciality.bind(this);
        this.setSelectedOptionHospital = this.setSelectedOptionHospital.bind(this);
        this.openCommentModal= this.openCommentModal.bind(this);
        this.closeCommentModal= this.closeCommentModal.bind(this);
        this.handleNotesChange = this.handleNotesChange.bind(this);

    }

    setSelectedOptionTitle(e){
        var selectedTitle = [{value:e.value, label:e.label}];
        const { surgeonInfo } = this.state;
        surgeonInfo["title"]=e.value;
        this.setState({
            selectedTitle: selectedTitle,
            surgeonInfo:surgeonInfo
        });
    }

    setSelectedOptionLevel(e){
        var selectedLevel = [{value:e.value, label:e.label}];
        const { surgeonInfo } = this.state;
        surgeonInfo["level"]=e.value;
        this.setState({
            selectedLevel: selectedLevel,
            surgeonInfo:surgeonInfo
        });
    }

    setSelectedOptionSpeciality(e){
        var selectedSpeciality = [{value:e.value, label:e.label}];
        const { surgeonInfo } = this.state;
        surgeonInfo["area_of_speciality"]=e.value;
        this.setState({
            selectedSpeciality: selectedSpeciality,
            surgeonInfo:surgeonInfo
        });
    }

    setSelectedOptionHospital = selectedHospital => {
        var hospitalData = [];
        selectedHospital.map((item) => {
            hospitalData.push({id: item.value});
        });

        this.setState({ 
            selectedHospital: selectedHospital,
            surgeonInfo: {
                ...this.state.surgeonInfo,
                hospitalData: hospitalData
            }
        });

       
    };

    showHospitalsList(hospitalsList) {
        var tempHospitals = [];
        hospitalsList.map((item)=> {
            var option = {value: item._id, label: item.name};
            tempHospitals.push(option);
        });
        this.setState({
            hospitalOption: tempHospitals,
        });
    }

    showXlsData(data) {
        var dataXlsx = [];

        data &&
            data.map(item => {
        
                dataXlsx.push({
                    surgeon_name: item.surgeons.fullName,
                    level: item.surgeons.level,
                    area_of_speciality: item.surgeons.area_of_speciality,
                    });
            });

        this.setState({
            dataXlsx: dataXlsx,
        });
    }
    

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            { 
                activePage: pageNumber }
            , () => {
                this.getList();
            }
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {

        this.setState(
            {
                activePage: activePage,
                totalItemsCount: totalItemsCount,
                limit: limit
            }
        );
    }

    getList() {
        this.props.dispatch(surgeonActions.getList({search: this.state.search,page: this.state.activePage, limit: this.state.limit}));
    }

    handleSearch(val) {
        this.setState({
            search: val,
            activePage:1
        },()=>{this.getList();});
        
    }

    addNewModalOpen() {
        
        this.setState({ addNewModal: !this.state.addNewModal});
    }


    nameHandler(val) {
        this.setState({
            name:val
        });
    }

    surgeonInfoHandler = (name, value) => {
        const { surgeonInfo } = this.state;
        surgeonInfo[name] = value;
        this.setState({
            surgeonInfo
        },()=>{ 
            if(name=="first_name"){
                this.getAutoSearch();
            }
        });
    };

    deleteModalClose() {
        this.setState({ 
            deleteModal:false,
            name:""
        },()=>{this.getList();});
    }

   

    getSingle(id) {
        this.props.dispatch(bookingActions.getHospitalsList({search: ""}));
        this.props.dispatch(surgeonActions.getById(id));
    }

    setEdit(data) {
        var hospitals=[];
        var selectedHospital=[];

        console.log("datadata",data);

        data.surgeonHospitalId.map((item)=>{
            if(item.hospitalId!=null){
                hospitals.push({id:item.hospitalId._id});
                selectedHospital.push({value:item.hospitalId._id,label:item.hospitalId.name});
            }
        });
        
        this.setState({
            surgeonInfo: {
                id:data._id,
                title:data.title,
                first_name:data.first_name,
                last_name:data.last_name,
                level:data.level,
                area_of_speciality:data.area_of_speciality,
                phone:data.phone,
                mobile:data.mobile,
                work_email:data.work_email,
                personal_email:data.personal_email,
                hospitalData:hospitals,
                notes:data.notes
            },
            selectedTitle:[{value:data.title,label:data.title}],
            selectedLevel:[{value:data.level,label:data.level}],
            selectedSpeciality:[{value:data.area_of_speciality,label:data.area_of_speciality}],
            selectedHospital:selectedHospital

          
        });


        this.editModalOpen();
    }
   
    editModalOpen() {

        this.setState({ editModal: true});
    }

    editModalClose() {
        this.setState({ 
            editModal: false,
            submitModalFalg:0,
            surgeonInfo: {
                id:"",
                title:"",
                first_name:"",
                last_name:"",
                level:"",
                area_of_speciality:"",
                phone:"",
                mobile:"",
                work_email:"",
                personal_email:"",
                hospitalData:[],
                notes:""
            },
            selectedTitle:[],
            selectedLevel:[],
            selectedSpeciality:[],
            selectedHospital:[],
            suggestionSurgeonList:[],
            suggestionsActive:false
        });
    }


    handleUpdate() {
        const { surgeonInfo } = this.state;
        this.setState({
            submitModalFalg:1
        });
        this.props.dispatch(surgeonActions.update(surgeonInfo));
    }

    deleteModalOpen() {
        this.setState({ deleteModal: true});
    }

    addNewModalClose() {
        this.setState({ addNewModal: false});
        
    }


    handleDelete(id) {
        this.setState({ 
            id: id
        });
        this.deleteModalOpen();
    }


    openCommentModal(id){
        this.setState({ 
            commentModal: true,
            surgeonId:id
        });
    }

    closeCommentModal(){
        this.setState({ 
            commentModal: false
        });
        this.getList();
    }

    getAutoSearch() {
        const { surgeonInfo } = this.state;
        
        surgeonService.getSuggestionList({ search: surgeonInfo["first_name"],page:1 })
            .then(response => {
                this.handleSuggestionSurgeonList(response.data);
            });

    }

    handleSuggestionSurgeonList(data){
        this.setState({
            suggestionSurgeonList:data,
            suggestionsActive:true
        });
    }

    handleOnSurgeonSelect(item) {

        const { surgeonInfo } = this.state;
        surgeonInfo["first_name"]=item.first_name;
        this.setState({
            suggestionSurgeonList:[],
            suggestionsActive:false,
            surgeonInfo
        });
    }


    handleNotesChange = (e) => {
        const { value } = e.target;
        this.setState((prevState) => ({
            surgeonInfo: {
                ...prevState.surgeonInfo,
                notes: value
            }
        }));
    };

   

    componentDidMount(){
        this.getList();
        this.props.dispatch(bookingActions.getHospitalsList({search: ""}));

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("nextprops", nextProps);
        if(nextProps.refreshList==true)
        {
            this.setState({ 
                activePage: 1
            },()=>{this.getList();});
            
        }

        if(nextProps.activePage != "" && nextProps.totalItemsCount != "" && nextProps.limit != ""){
            this.handlePageStates(nextProps.activePage, nextProps.totalItemsCount, this.state.limit);
        }

        if(nextProps.modal==false)
        {
            this.addNewModalClose();
        }


        if(nextProps.editModal==true)
        {
            this.setEdit(nextProps.list);
        }

        if(nextProps.editModal==false)
        {
            this.editModalClose();
        }


        if(nextProps.hospitalsList !== undefined) {
            this.showHospitalsList(nextProps.hospitalsList);
        }
        
        if (nextProps.lists !== undefined) {
            this.showXlsData(nextProps.lists);
        }
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if(this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        const { lists,error,loading } = this.props;
        const { submitModalFalg } =this.state;
        return (
            <div>
                <Navbar activePage="surgeons"/>
                <div className="container">    
                    <div className="pt-4 row align-items-center">
                        <div className="col-md-3">
                            <div className="sub-menu">
                                <h1 className="heading">Surgeons</h1>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <form className="form-search">
                                <Input
                                    className="form-control mr-sm-2"
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.search}
                                    onChange={e =>
                                        this.handleSearch(e.target.value)
                                    }
                                />
                            </form>
                        </div>
                        {/* <div className="col-md-3">
                            <button className="btn btn-primary add_option" onClick={this.addNewModalOpen}><i className="fa fa-plus"></i> <span>Add New</span></button>
                        </div> */}
                        <div className="col-md-6 text-right">
                            <button className="btn btn-primary add_option mr-3" onClick={this.addNewModalOpen}><i className="fa fa-plus"></i> <span>Add New</span></button>
                            < ExportSheet
                                header={head}
                                fileName={"surgeons"}
                                dataSource={this.state.dataXlsx}
                                xlsx={XLSX}
                            >
                                <button
                                    className="btn btn-default add_print"
                                >
                                    Print
                                </button>
                            </ExportSheet >
                        </div>
                    </div>

                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>              
                                    <th scope="col" style={{minWidth:"250px"}}>Surgeon Name</th>
                                    <th scope="col" style={{minWidth:"100px"}}>Level</th>
                                    <th scope="col" style={{minWidth:"100px"}}>Area of Speciality</th>
                                    <th scope="col" className="text-right" width="100">Actions</th>                
                                </tr>
                            </thead>
                            <tbody>
                                {   lists && lists.map((item,ind) => (
                                    <tr key={item._id}>           
                                        <td data-th="Hospital Name">
                                            <div className=""> 
                                                <strong><a onClick={() => this.getSingle(item.surgeons._id)} href="javascript:;">{item.surgeons.title }. {item.surgeons.first_name } {item.surgeons.last_name }</a></strong>   
                                            </div>
                                        </td>
                                        <td data-th="Contact Person">{item.surgeons.level}</td>
                                        <td data-th="Switch Board Phone">{item.surgeons.area_of_speciality}</td>
                                        <td className="text-right">
                                            <div className="d-flex align-items-center">
                                                <span className="position-relative" >
                                                    <a id={"UncontrolledTooltipComment1"+ind} style={{fontSize: "18px"}} onClick={() => { this.openCommentModal(item.surgeons._id); }} href="javascript:;" ><i className="fa fa-comment"></i> 
                                                    </a>
                                                    <UncontrolledTooltip
                                                        target={"UncontrolledTooltipComment1"+ind}
                                                    > 
                                                        Comment
                                                    </UncontrolledTooltip>
                                                    {item.commentCount>0 &&
                                                        <span className="comment-bages" >{item.commentCount}</span>
                                                    }
                                                </span>
                                                <div className="ml-3  action-area dropdown">
                                                    <a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="dots">...</span>
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">                       
                                                        <a className="dropdown-item" onClick={() => this.getSingle(item.surgeons._id)} href="javascript:;">Edit</a>
                                                        <a className="dropdown-item" onClick={() => this.handleDelete(item.surgeons._id)}  href="javascript:;">Delete</a>                    
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                { lists && lists.length==0 && loading==false && 
                                    <tr className="text-center">
                                        <td colSpan="6">No Record Found</td>
                                    </tr>
                                }
                               
                            </tbody>
                        </table>
                    </div>
                </div>
                {lists && lists.length >0 &&
                <div>
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center mb-5"
                        activeLinkClass="active"
                        nextPageText="Next"
                        prevPageText="Prev"
                    />
                    <br className="d-block d-sm-none"/><br className="d-block d-sm-none"/>
                </div>
                }
                <AddBox addNewModalClose={this.addNewModalClose.bind(this)} addNewModalProp={this.state.addNewModal} />
                <Comment surgeonid={this.state.surgeonId} commentModal={this.state.commentModal} closeCommentModal={this.closeCommentModal.bind(this)} />
                <Modal size="lg" backdrop="static" isOpen={this.state.editModal} toggle={() => this.editModalClose()}>
                    <ModalHeader className="" toggle={() => this.editModalClose()}>
                        Edit Surgeon
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Title <span className="text-danger">*</span></label>
                                        <Select 
                                            value={this.state.selectedTitle}
                                            onChange={this.setSelectedOptionTitle}
                                            options={titleOption}
                                        /> 
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.title ? error.title : ""}</span> : ""}
                                    </div>

                                    <div className="form-group">
                                        <label>First Name <span className="text-danger">*</span></label>
                                        <div className="autocomplete">
                                            <div className="wrapper remove-rounder">
                                                <div className="sc-hKMtZM">
                                                    <Input 
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.surgeonInfo.first_name}
                                                        onChange = {(e) => this.surgeonInfoHandler("first_name", e.target.value)}
                                                    />
                                                    {this.state.suggestionsActive && this.state.suggestionSurgeonList.length>0 && this.state.surgeonInfo.first_name!="" &&
                                                        <ul className="suggestions">

                                                            {this.state.suggestionSurgeonList.map((item, index) => 
                                                                <li
                                                                    key={index}
                                                                    onClick={()=>this.handleOnSurgeonSelect(item)}
                                                                >
                                                                    <i className="fa fa-search suggestionsSearch"></i>
                                                                    <span className="suggestionsText">{ item.first_name }</span>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    }
                                                </div>
                                                {submitModalFalg == 1 && error ? <span className="text-danger">{error.first_name ? error.first_name : ""}</span> : ""}

                                            </div>
                                        </div>       
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name <span className="text-danger">*</span></label>
                                        <Input 
                                            className="form-control"
                                            value={this.state.surgeonInfo.last_name}
                                            onChange = {(e) => this.surgeonInfoHandler("last_name", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.last_name ? error.last_name : ""}</span> : ""}
                                    </div> 
                                    <div className="form-group">
                                        <label>Level <span className="text-danger">*</span></label>
                                        <div className="position_up" style={{zIndex:7}}>
                                            <Select 
                                                value={this.state.selectedLevel}
                                                onChange={this.setSelectedOptionLevel}
                                                options={levelOption}
                                            /> 
                                            {submitModalFalg == 1 && error ? <span className="text-danger">{error.level ? error.level : ""}</span> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Area of Speciality <span className="text-danger">*</span></label>
                                        <Select 
                                            value={this.state.selectedSpeciality}
                                            onChange={this.setSelectedOptionSpeciality}
                                            options={specialityOption}
                                        /> 
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.area_of_speciality ? error.area_of_speciality : ""}</span> : ""}
                                    </div>
                                   
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Phone</label>
                                        <Input 
                                            type="number"
                                            className="form-control"
                                            value={this.state.surgeonInfo.phone}
                                            onChange = {(e) => this.surgeonInfoHandler("phone", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.phone ? error.phone : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Mobile</label>
                                        <Input 
                                            type="number"
                                            className="form-control"
                                            value={this.state.surgeonInfo.mobile}
                                            onChange = {(e) => this.surgeonInfoHandler("mobile", e.target.value)}
                                        />
                                        {error ? <span className="text-danger">{error.mobile ? error.mobile : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Work Email</label>
                                        <Input 
                                            className="form-control"
                                            value={this.state.surgeonInfo.work_email}
                                            onChange = {(e) => this.surgeonInfoHandler("work_email", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.work_email ? error.work_email : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Personal Email</label>
                                        <Input 
                                            className="form-control"
                                            value={this.state.surgeonInfo.personal_email}
                                            onChange = {(e) => this.surgeonInfoHandler("personal_email", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.personal_email ? error.personal_email : ""}</span> : ""}
                                    </div>

                                    <div className="form-group">
                                        <label>Hospital</label>
                                        <Select 
                                            value={this.state.selectedHospital}
                                            onChange={this.setSelectedOptionHospital}
                                            options={this.state.hospitalOption}
                                            isMulti={true}
                                        /> 
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.hospitalData ? error.hospitalData : ""}</span> : ""}
                                    </div>

                                    <div className="form-group">
                                            <label>Notes</label>
                                        <Input
                                            type="textarea"
                                            className="form-control"
                                            name=""
                                            value={this.state.surgeonInfo.notes}
                                            onChange={this.handleNotesChange}
                                            placeholder="Type operation name & kit name"

                                        />
                                    {submitModalFalg == 1 && error ? <span className="text-danger">{error.notes ? error.notes : ""}</span> : ""}

                                    </div>
                                </div>
                            </div>    
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.editModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false && 
                                <button className="btn btn-primary" onClick={this.handleUpdate}>Update</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }

                        </div>
                    </ModalFooter>
                </Modal>

                {/* Edit Modal */}

                <DeleteBox deleteModalClose={this.deleteModalClose.bind(this)} id={this.state.id} deleteModalProps={this.state.deleteModal} />

                {/* Delete Modal */}
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { hospitalsList } = state.rootReducer.bookings;
    const { lists, activePage, totalItemsCount, limit, error, modal, editModal, list, refreshList, loading, deleteModal } = state.rootReducer.surgeons;

    return {
        lists,
        error,
        modal,
        editModal,
        refreshList,
        list,
        loading,
        deleteModal,
        activePage,
        totalItemsCount,
        limit,
        hospitalsList
    };
}

export default connect(mapStateToProps)(Index);