import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import DashboardSidebar from "./NewDashboardSidebar";
import Select from "react-select";
import { Input } from "reactstrap";
import { bookingService } from "../../../../services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BookingTransfer = () => {
    const [error, setError] = useState({});
    const [selectedFromTransfer, setSelectedFromTransfer] = useState([]);
    const [selectedToTransfer, setSelectedToTransfer] = useState([]);
    const [transferFromList, setTransferFromList] = useState([]);
    const [transferToList, setTransferToList] = useState([]);
    const [transferType,setTransferType] = useState("transfer");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setError({});
        getRepsList();
    }, []);

    const getRepsList = async () => {
        // setData([]);
        setSelectedFromTransfer([]);
        setSelectedToTransfer([]);
        
        try {
            const res = await bookingService.getRepsList({
                search: ""
            });
            showRepsList(res.data);
            //setLoading(false);
        } catch (error) {
            console.error("Error: ", error);
            setError(error);
            //setLoading(false);
        }
    };

    const showRepsList = (repsList) => {
        var tempRepsList = [];
        repsList.map(item => {
            var option = {
                value: item._id,
                label: item.first_name + " " + item.last_name,
            };
            tempRepsList.push(option);
        });

        setTransferToList(tempRepsList);
        setTransferFromList(tempRepsList);
    };

    const handleTransferTo = (e) => {
        setError({...error,transfer_to:""});
        setSelectedToTransfer(e);
    };

    const handleTransferFrom = selectedAdditionalRep => {
        setError({...error,transfer_from:""});
        setSelectedFromTransfer(selectedAdditionalRep);
    };

    const handleTransferType = val => {
        setError({...error,booking_transfer_type:""});
        setTransferType(val);
    };

    const handleSubmit = async() => {
        setLoading(true);
        var payload = {
            transfer_from: selectedFromTransfer?.value ? selectedFromTransfer.value : "",
            transfer_to: selectedToTransfer?.value ? selectedToTransfer.value : "",
            booking_transfer_type: transferType
        };
        try{
            await bookingService.bookingTransfer(payload);
            toast.success(transferType === "transfer" ? "Bookings transferred successfully." : "Bookings duplicated successfully.", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setSelectedFromTransfer([]);
            setSelectedToTransfer([]);
            setTransferType("transfer");
            setError({});
            setLoading(false);
        }catch(error){
            setError(error);
            setLoading(false);
        }
    };


    return (
        <div>
            <ToastContainer />
            <Navbar activePage="dashboard" />
            <main className="offset">
                <div className="container-fluid">
                    <div className="row pt-4">
                        <div className="col-lg-2 side-filters">
                            <span className="side-heading">Dashboard</span>
                            <DashboardSidebar
                                activePage="booking-transfer"
                            />
                        </div>
                        <div className="col-lg-10">
                            <div className="ground">
                                <div className="row pt-4 mobile_nocard">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-8">
                                        <div className="d-card">
                                            <h4>Booking Transfer</h4>
                                            <div className="form-group">
                                                <label>
                                                    Transfer From{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div
                                                    className="position_up"
                                                    style={{ zIndex: 2 }}>
                                                    <Select
                                                        value={selectedFromTransfer}
                                                        onChange={handleTransferFrom}
                                                        options={transferFromList}
                                                    />
                                                    {error ? (
                                                        <span className="text-danger">
                                                            {error.transfer_from ? error.transfer_from : ""}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    Transfer To{" "}
                                                    <span className="text-danger">*</span>
                                                </label>

                                                <div
                                                    className="info position_up"
                                                    style={{ zIndex: 1 }}>
                                                    <Select
                                                        value={selectedToTransfer}
                                                        onChange={handleTransferTo}
                                                        options={transferToList}
                                                        maxMenuHeight={290}
                                                    />
                                                    {error ? (
                                                        <span className="text-danger">
                                                            {error.transfer_to
                                                                ? error.transfer_to
                                                                : ""}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>

                                            <div className="row pt-1">
                                                <div className="form-check mx-3">
                                                    <Input className="form-check-input" type="radio" value="transfer" onChange={(e)=>handleTransferType(e.target.value)} id="flexRadioDefault1" checked={transferType =="transfer" ? true : false} />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                        Transfer
                                                    </label>
                                                </div>

                                                <div className="form-check mx-2">
                                                    <Input className="form-check-input" type="radio" value="duplicate" onChange={(e)=>handleTransferType(e.target.value)} id="flexRadioDefault2" checked={transferType =="duplicate" ? true : false} />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                        Duplicate
                                                    </label>
                                                </div>
                                            </div>
                                                {error ? (
                                                        <span className="text-danger">
                                                            {error.booking_transfer_type
                                                                ? error.booking_transfer_type
                                                                : ""}
                                                        </span>
                                                    ) : (
                                                    ""
                                                )}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 row mobile_nocard">
                                    <div className="col-lg-7 col-md-7 col-sm-6"></div>
                                    <div className="col-lg-2 col-md-2 col-sm-3">
                                        <a
                                            href="javascript:;"
                                            onClick={() => !loading ? handleSubmit() : ""}
                                            className="btn btn-block btn-primary">
                                            Save
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};
export default BookingTransfer;

