import { authHeader,check419 } from "../helpers";
// import dateFormat from "dateformat";

export const bookingService = {
    checkHolidayDate,
    getHolidayList,
    getHospitalsList,
    getRepsList,
    getKitSetByKit,
    add,
    getList,
    getById,
    getHospitalDetailsById,
    getSetList,
    getSetListForReps,
    update,
    updateStatus,
    updateItemUsedStatus,
    updateItemUsedStatusForReps,
    saveReturn,
    updatePoNumber,
    countByStatus,
    countDispatch,
    saveDispatchs,
    saveCollections,
    updateBookingDates,
    getLog,
    sendMailBeforeBookingDispatch,
    dashboardPOChecked,
    repsUpdate,
    getBookingkitSetList,
    bookingTransfer,
    delete: _delete,
};

function getHospitalsList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "hospitals/searchHospitals",
        requestOptions,
    )
        .then(handleResponse)
        .then(hospitalsList => {
            return hospitalsList;
        });
}

function dashboardPOChecked(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/dashboardPOChecked",
        requestOptions,
    )
        .then(handleResponse)
        .then(updatedList => {
            return updatedList;
        });
}

function sendMailBeforeBookingDispatch(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/sendMailBeforeBookingDispatch",
        requestOptions,
    )
        .then(handleResponse)
        .then(mailResp => {
            return mailResp;
        });
}

function getRepsList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "users/searchUsers",
        requestOptions,
    )
        .then(handleResponse)
        .then(repsList => {
            return repsList;
        });
}

function getKitSetByKit(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL +
            "sets/getAllSetsUsingKitIdAndBookingIdForEdit",
        requestOptions,
    )
        .then(handleResponse)
        .then(kitSetList => {
            return kitSetList;
        });
}

function getHolidayList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "holidays/getHolidayList",
        requestOptions,
    )
        .then(handleResponse)
        .then(holidayList => {
            return holidayList;
        });
}

function checkHolidayDate(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };
    console.log(requestOptions.body);
    return fetch(
        process.env.REACT_APP_APIURL + "holidays/checkHolidayUsingDate",
        requestOptions,
    )
        .then(handleResponse)
        .then(holidayDate => {
            return holidayDate;
        });
}

function getList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/getBookings",
        requestOptions,
    )
        .then(handleResponse)
        .then(bookingList => {
            return bookingList;
        });
}

function getById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        process.env.REACT_APP_APIURL + `booking/editBooking/${id}`,
        requestOptions,
    ).then(handleResponse);
}

function getHospitalDetailsById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        process.env.REACT_APP_APIURL + `hospitals/getHospital/${id}`,
        requestOptions,
    ).then(handleResponse);
}

function getSetList(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        process.env.REACT_APP_APIURL + `booking/used-item-edit/${id}`,
        requestOptions,
    ).then(handleResponse);
}

function getSetListForReps(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        process.env.REACT_APP_APIURL + `reps/booking/used-item-edit/${id}`,
        requestOptions,
    ).then(handleResponse);
}

function add(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/saveBookings",
        requestOptions,
    ).then(handleResponse);
}

function update(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/updateBookings",
        requestOptions,
    ).then(handleResponse);
}

function updateStatus(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/updateBookingStatus",
        requestOptions,
    ).then(handleResponse);
}

function updateItemUsedStatus(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/used-item-update",
        requestOptions,
    ).then(handleResponse);
}

function updateItemUsedStatusForReps(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "reps/booking/used-item-update",
        requestOptions,
    ).then(handleResponse);
}

function saveReturn(returnInfo) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(returnInfo),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/saveReturns",
        requestOptions,
    ).then(handleResponse);
}

function updatePoNumber(data) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(data),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/updatePONumber",
        requestOptions,
    ).then(handleResponse);
}

function countByStatus(info) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(info),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/countBookings",
        requestOptions,
    )
        .then(handleResponse)
        .then(countInfo => {
            return countInfo;
        });
}

function countDispatch(info) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(info),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/countBookings",
        requestOptions,
    )
        .then(handleResponse)
        .then(countDispatch => {
            return countDispatch;
        });
}

function saveDispatchs(info) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(info),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/saveDispatchs",
        requestOptions,
    ).then(handleResponse);
}

function saveCollections(info) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(info),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/saveCollections",
        requestOptions,
    ).then(handleResponse);
}

function updateBookingDates(info) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(info),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/updateBookingDates",
        requestOptions,
    ).then(handleResponse);
}

function getLog(info) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(info),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/getBookingLogUsingBookingId",
        requestOptions,
    )
        .then(handleResponse)
        .then(logList => {
            return logList;
        });
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(id),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "hospitals/deleteHospitals",
        requestOptions,
    ).then(handleResponse);
}

function repsUpdate(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/updateRepsAndAdditionalRepsInBooking",
        requestOptions,
    ).then(handleResponse);
}

function getBookingkitSetList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "sets/getBookigKitSetsUsingBookingId",
        requestOptions,
    )
        .then(handleResponse)
        .then(bookingList => {
            return bookingList;
        });
}

function bookingTransfer(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/getTransferOrDuplicateBookings",
        requestOptions,
    ).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            } else if(response.status === 419){
                check419();
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}
