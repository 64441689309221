import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import FilterTooltip from "./Filter";

import { bookingActions, setActions } from "../../../../actions";
import { bookingService } from "../../../../services";
import dateFormat from "dateformat";
import moment from "moment";
import * as XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
//import { DatePicker } from "reactstrap-date-picker";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import { ReactComponent as FilterIcon } from "../../../../assets/images/filter.svg";
import RepsUpdateModal from "./RepsUpdateModal";


const head = [
    { title: "Id", dataIndex: "Id" },
    { title: "Hospital Name", dataIndex: "Hospital Name" },
    { title: "Dispatch Date", dataIndex: "Dispatch Date" },
    { title: "Delivery Time", dataIndex: "Delivery Time" },
    { title: "Operation Date", dataIndex: "Operation Date" },
    { title: "Slot", dataIndex: "Slot" },
    { title: "Notes", dataIndex: "Notes" },
    { title: "Kit", dataIndex: "Kit" },
];

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");

class Index extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            newBookingList: [],
            todayDate: "",
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 0,
            kitSetDetailModal: false,
            kitSetData: [],
            kitDetailsClickFlag: 0,
            kitDetails: [],
            cancelModal: false,
            cancelId: 0,
            dateModal: false,
            bookingId: 0,
            bokkingDate: [
                {
                    date_delivery_date: "",
                    date_collection_date: "",
                    date_operation_date: "",
                },
            ],
            dateSubmit: 0,
            dataXlsx: [],
            setList: [],
            setStatus: [],
            itemUsedModal: false,

            searchOperationFromDate: "",
            filterOperationFromDate: "",

            searchDeliveryFromDate: "",
            searchDeliveryToDate: "",
            filterDeliveryFromDate: "",
            filterDeliveryToDate: "",
            delete_comment: "",
            error: false,
            filterModal:false,
            filterPoChase: "All",
            filterCreateDate:"",
            repsUpdateModal: false,
            bookingRepsUpdateId: 0,
            searchReps:"",
            isLoading:false,
            deliveryDateIsDisabled:true
        };

        // fromSearchDate filterOperationFromDate

        this.getList = this.getList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.cancelModalClose = this.cancelModalClose.bind(this);
        this.dateModalClose = this.dateModalClose.bind(this);
        this.dateModalOpen = this.dateModalOpen.bind(this);
        this.arrangeBookingList = this.arrangeBookingList.bind(this);
        this.itemUsedModalOpen = this.itemUsedModalOpen.bind(this);
        this.itemUsedModalClose = this.itemUsedModalClose.bind(this);
        this.updateSetStatus = this.updateSetStatus.bind(this);
        this.itemUsedUpdate = this.itemUsedUpdate.bind(this);
        this.repsUpdateModalOpen = this.repsUpdateModalOpen.bind(this);
    }

    handleFilterToggle(){
        this.setState(
            {
                filterModal: !this.state.filterModal
            }
          
        ); 
    }

    handleFilterRefreesh (data){
        this.setState({
                filterModal: !this.state.filterModal,
                filterPoChase:data.filterPoChase,
                filterCreateDate:data.filterCreateDate,
                activePage:1
            },()=>{this.getList();}
          
        ); 
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                this.getList();
            },
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {
        this.setState({
            activePage: activePage,
            totalItemsCount: totalItemsCount,
            limit: limit,
        });
    }

    getList() {
        this.setState({isLoading : true});
        this.props.dispatch(
            bookingActions.getList({
                working_delivery_from_date: this.state.filterDeliveryFromDate,
                working_delivery_to_date: this.state.filterDeliveryToDate,
                type: ["loan"],
                status: ["booked", "dispatch", "return"],
                operation_from_date: this.state.filterOperationFromDate,
                search: this.state.search,
                page: this.state.activePage,
                limit: this.state.limit,
                po_check: this.state.filterPoChase,
                created_date:this.state.filterCreateDate
        }),
        );
        setTimeout(()=>{
            this.setState({isLoading : false});
        },500);
    }

    handleSearch(val) {
        this.setState(
            {
                search: val,
                activePage: 1
            },
            () => {
                this.getList();
            },
        );
    }

    handleSearchOperationFromDate(val) {
        this.setState(
            {
                searchOperationFromDate: val != null ? new Date(val) : "",
                filterOperationFromDate:
                    val != null ? moment(val).format("YYYY-MM-DD") : "",
                activePage: 1
            },
            () => {
                this.getList();
            },
        );
    }

    handleSearchDeliveryFromDate(val) {
        this.setState(
            {
                searchDeliveryFromDate: val != null ? new Date(val) : "",
                filterDeliveryFromDate:
                    val != null ? moment(val).format("YYYY-MM-DD") : "",
                activePage: 1
            },
            () => {
                this.getList();
            },
        );
    }

    handleSearchDeliveryToDate(val) {
        this.setState(
            {
                searchDeliveryToDate: val != null ? new Date(val) : "",
                filterDeliveryToDate:
                    val != null ? moment(val).format("YYYY-MM-DD") : "",
                activePage: 1
            },
            () => {
                this.getList();
            },
        );
    }

    handleSearchDeliveryDate() {
        //var date = moment().toISOString();
        this.setState(
            {
                search: "",
                searchDeliveryFromDate: "",
                searchDeliveryToDate: "",
                filterDeliveryFromDate:"",
                filterDeliveryToDate: "",
                searchOperationFromDate: "",
                filterOperationFromDate: "",

                activePage: 1,
            },
            () => {
                this.getList();
            },
        );
    }

    kitSetDetailModalClose() {
        this.setState({
            kitSetDetailModal: false,
            kitSetData: [],
        });
    }

    itemUsedModalOpen(id) {
        bookingService.getSetList(id).then(res => {
            this.setState({
                setList: res.data[0].bookingKitSetId,
                itemUsedModal: true,
            });
        });
    }

    itemUsedModalClose() {
        this.setState({
            itemUsedModal: false,
        });
    }

    updateSetStatus(val, index) {
        let setList = this.state.setList;
        setList[index].item_in_used = val;
        this.setState({ setList: setList });
    }

    itemUsedUpdate() {
        let data = [];
        let setListData = this.state.setList;

        setListData.map(item => {
            data.push({ id: item._id, item_in_used: item.item_in_used });
        });

        bookingService.updateItemUsedStatus({ data: data }).then(() => {
            this.itemUsedModalClose();
        });
    }

    showKitDetails(kitDetails) {
        if (this.state.kitDetailsClickFlag == 1) {
            this.setState({
                kitDetails: kitDetails,
                kitSetDetailModal: true,
                kitDetailsClickFlag: 0,
            });
        }
    }

    handleKitDetails(id) {
        this.setState({
            kitDetailsClickFlag: 1,
        });

        this.props.dispatch(setActions.getById(id));
    }

    cancelModalOpen(id) {
        this.setState({
            delete_comment: "",
            error: false,
            cancelModal: true,
            cancelId: id,
        });
    }

    cancelModalClose() {
        this.setState({
            cancelModal: false,
        });
    }

    handleCancle() {
        this.setState({ error: true });

        this.props.dispatch(
            bookingActions.updateStatus({
                id: this.state.cancelId,
                delete_comment: this.state.delete_comment,
                status: "cancel",
            }),
        );
    }

    dateModalOpen(item) {
        this.setState({
            dateModal: true,
            bookingId: item.bookings._id,
            dateSubmit: 0,
            bokkingDate: {
                id: item.bookings._id,
                log_status: "updated",
                log_by_id: this.state.user.data._id,
                delivery_date: dateFormat(
                    item.bookings.delivery_date,
                    "yyyy-mm-dd",
                ),
                collection_date: dateFormat(
                    item.bookings.collection_date,
                    "yyyy-mm-dd",
                ),
                operation_date: dateFormat(
                    item.bookings.operation_date,
                    "yyyy-mm-dd",
                ),
            },
            deliveryDateIsDisabled:item?.bookingdispatches?.length > 0 && item.bookingcollections.length === 0 ? false : true
        });
    }

    dateModalClose() {
        this.setState({
            delete_comment: "",
            dateModal: false,
            deliveryDateIsDisabled:true
        });
    }

    handleCollectionDate(val) {
        const { bokkingDate } = this.state;
        bokkingDate.collection_date = val;
        this.setState({
            bokkingDate: bokkingDate,
        });
    }

    handleOprationDate(val) {
        const { bokkingDate } = this.state;
        bokkingDate.operation_date = val;
        this.setState({
            bokkingDate: bokkingDate,
        });
    }

    handleDeliveryDate(val) {
        const { bokkingDate } = this.state;
        bokkingDate.delivery_date = val;
        this.setState({
            bokkingDate: bokkingDate,
        });
    }

    handleUpdateDate() {
        this.setState({
            dateSubmit: 1,
        });
        this.props.dispatch(
            bookingActions.updateBookingDates(this.state.bokkingDate),
        );
    }

    arrangeBookingList(bookingList) {
        var newBookingList = [];
        var prevDate = "";
        var prevIndex = 0;
        var todayDate = "";
        var dateArray = [];

        bookingList.map((bookingItem, index) => {
            var currDate = bookingItem.bookings.working_delivery_date;

            if (currDate !== prevDate) {
                if (index != 0) {
                    prevIndex = prevIndex + 1;
                }
                prevDate = bookingItem.bookings.working_delivery_date;
                newBookingList[prevIndex] = [];
            }

            todayDate = moment().format("YYYY-MM-DD");
            var dateToCheck = moment(currDate).format("YYYY-MM-DD");

            // if( moment(currDate).format("DD/MM/YYYY") == todayDate) {
            //     todayIndex = prevIndex;
            // }
            if (moment(dateToCheck).isSameOrAfter(todayDate)) {
                if (!dateArray.includes(prevIndex)) {
                    dateArray.push(prevIndex);
                }
            }

            newBookingList[prevIndex].push(bookingItem);
        });

        // if(todayIndex !== "") {
        //     var todayBooking = newBookingList[todayIndex];
        //     newBookingList.splice(todayIndex, 1);
        //     newBookingList.unshift(todayBooking);
        // }

        // for(var i=0; i<dateArray.length; i++) {
        //     var booking = newBookingList[dateArray[i]];
        //     newBookingList.splice(dateArray[i], 1);
        //     newBookingList.unshift(booking);
        // }
        
        this.setState({
            newBookingList: newBookingList,
            todayDate: todayDate,
        });
    }

    capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    showXlsData(data) {
        var dataXlsx = [];
        var todayIndex = [];

        data.map((item, index) => {
            var kitDataArray = [];

            item.bookingKitSetId.map(kitdata => {
                if (!kitDataArray.includes(kitdata.kitData[0].name)) {
                    kitDataArray.push(kitdata.kitData[0].name);
                }
            });

            dataXlsx.push({
                Id: item.bookings.booking_id,
                "Hospital Name": item.hospitalData[0].name,
                "Dispatch Date": moment(item.bookings.delivery_date).format(
                    "ddd, MMMM Do",
                ),
                "Operation Date": moment(item.bookings.operation_date).format(
                    "ddd, MMMM Do",
                ),
                Slot: item.bookings.operation_date_slot,
                "Delivery Time": item.bookings.delivery_date_slot,
                Notes: item.bookings.additional_notes,
                Kit: kitDataArray.join(),
            });

            var todayDate = moment().format("DD/MM/YYYY");

            if (
                moment(item.bookings.operation_date).format("DD/MM/YYYY") ==
                todayDate
            ) {
                todayIndex.push(index);
            }
        });

        if (todayIndex.length !== 0) {
            for (var i = 0; i < todayIndex.length; i++) {
                var todayBooking = dataXlsx[todayIndex[i]];
                dataXlsx.splice(todayIndex[i], 1);
                dataXlsx.unshift(todayBooking);
            }
        }

        this.setState({
            dataXlsx: dataXlsx,
        });
    }

    deleteCommentHandler(val) {
        this.setState({
            delete_comment: val,
        });
    }

    handlePOCheck(ev,item,mainInd,index) {

        let newBookingList = this.state.newBookingList;
        newBookingList[mainInd][index].bookings.po_number=!item?.bookings?.po_check==true?"":item.bookings.po_number;
        newBookingList[mainInd][index].bookings.po_check=ev.target.checked;
        this.setState({newBookingList:newBookingList});
        bookingService
            .dashboardPOChecked({
                id: item?.bookings._id,
                po_check: ev.target.checked,
                po_number: ev.target.checked==true?"":item.bookings.po_number,
            })
            .then(res => {
                console.log("res", res);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }

    updatePONumber(ev,item,mainInd,index) {
        let newBookingList = this.state.newBookingList;
        newBookingList[mainInd][index].bookings.po_number=ev.target.value;
        newBookingList[mainInd][index].bookings.po_check= ev.target.value==""?true:false;

        this.setState({newBookingList:newBookingList});
        bookingService
            .dashboardPOChecked({
                id: item?.bookings._id,
                po_check:  ev.target.value==""?true:false,
                po_number: ev.target.value,
            })
            .then(res => {
                console.log("res", res);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }

    updateMouseLeave(item,mainInd,index) {
        let newBookingList = this.state.newBookingList;
        newBookingList[mainInd][index].bookings.po_hover=undefined;
        this.setState({newBookingList:newBookingList});
    }

    updateMouseEnter(item,mainInd,index) {
        let newBookingList = this.state.newBookingList;
        newBookingList[mainInd][index].bookings.po_hover=1;
        this.setState({newBookingList:newBookingList});
    }

    repsUpdateModalOpen(item) {
        if(item?.bookings?._id){
            this.props.dispatch(bookingActions.getRepsList({ search: this.state.searchReps }));
            setTimeout(() => {
                this.props.dispatch(bookingActions.getById(item.bookings._id));
            }, 500);
        }
        this.setState({
            repsUpdateModal: true,
            bookingRepsUpdateId: item.bookings._id
        });
    }

    repsUpdateModalClose(flag) {
        if(flag){
            this.getList();
            this.setState({
                repsUpdateModal: false,
            });
        }else{
            this.setState({
                repsUpdateModal: false,
            });
        }
    }


    componentDidMount() {
        this.setState({isLoading : true});
        setTimeout(() => {
            this.handleSearchDeliveryFromDate(moment().toISOString());
        }, 1000);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log(nextProps);
        if (nextProps.refreshList == true || nextProps.redirect == true) {
            this.setState({isLoading : true});
            this.setState(
                {
                    activePage: 1,
                    cancelModal: false,
                    dateModal: false,
                },
                () => {
                    this.getList();
                },
            );
        }

        if (
            nextProps.activePage != "" &&
            nextProps.totalItemsCount != undefined &&
            nextProps.totalItemsCount != "" &&
            nextProps.limit != ""
        ) {
            this.handlePageStates(
                nextProps.activePage,
                nextProps.totalItemsCount,
                this.state.limit,
            );
        }

        if (nextProps.bookingList != undefined) {
            this.arrangeBookingList(nextProps.bookingList);
        }

        if (nextProps.bookingList != undefined) {
            this.showXlsData(nextProps.bookingList);
        }

        if (nextProps.editSet == true) {
            this.showKitDetails(nextProps.set);
        }
    }

    render() {
        const { loading, error } = this.props;
        const { bokkingDate } = this.state;

        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const todayDate = `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date < 10 ? `0${date}` : `${date}`}`;
        // console.log(todayDate);
        return (
            <div>
                <Navbar activePage="bookings" />
                <div className="container-fluid">
                    <div className="row pt-4">
                        <div className="col-md-6 col-lg-4">
                            <ul className="sub-menu">
                                <li>
                                    <Link
                                        to="/admin/bookings"
                                        className="active">
                                        Open
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/bookings/consignment">
                                        Consignment
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/bookings/close">
                                        Closed
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/bookings/cancel">
                                        Cancelled
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-2 mb-5 mb-md-4 mb-lg-0">
                            <form className="form-search">
                                <Input
                                    className="form-control mr-sm-2"
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.search}
                                    onChange={e =>
                                        this.handleSearch(e.target.value)
                                    }
                                />
                            </form>
                        </div>
                        <div className="col-10 col-md-6 col-lg-3">
                            <div className="label_adjust mx-lg-auto">
                                <label className="small mb-0">
                                    Delivery Date{" "}
                                </label>
                                <div className="row no-gutters">
                                    <div className="col">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            showClearButton={false}
                                            placeholderText="From"
                                            selected={
                                                this.state
                                                    .searchDeliveryFromDate
                                            }
                                            onChange={e =>
                                                this.handleSearchDeliveryFromDate(
                                                    e,
                                                )
                                            }
                                            className="form-control"
                                            customInput={
                                                <MaskedInput
                                                    pipe={autoCorrectedDatePipe}
                                                    mask={[
                                                        /\d/,
                                                        /\d/,
                                                        "/",
                                                        /\d/,
                                                        /\d/,
                                                        "/",
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                    keepCharPositions={true}
                                                    guide={true}
                                                    showMask={false}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="col">
                                        <div className="mx-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                showClearButton={false}
                                                placeholderText="To"
                                                selected={
                                                    this.state
                                                        .searchDeliveryToDate
                                                }
                                                onChange={e =>
                                                    this.handleSearchDeliveryToDate(
                                                        e,
                                                    )
                                                }
                                                className="form-control"
                                                customInput={
                                                    <MaskedInput
                                                        pipe={
                                                            autoCorrectedDatePipe
                                                        }
                                                        mask={[
                                                            /\d/,
                                                            /\d/,
                                                            "/",
                                                            /\d/,
                                                            /\d/,
                                                            "/",
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                        ]}
                                                        keepCharPositions={true}
                                                        guide={true}
                                                        showMask={false}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="label_adjust">
                                            <label className="small mb-0">
                                                Operation Date{" "}
                                            </label>
                                            <span className="small ml-2 btn-clear">
                                                <a
                                                    style={{
                                                        position: "relative",
                                                        top: "5px",
                                                    }}
                                                    href="javascript:;"
                                                    onClick={() =>
                                                        this.handleSearchDeliveryDate()
                                                    }>
                                                    Clear
                                                </a>
                                            </span>
                                            <div className="no-gutters">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    showClearButton={false}
                                                    selected={
                                                        this.state
                                                            .searchOperationFromDate
                                                    }
                                                    onChange={e =>
                                                        this.handleSearchOperationFromDate(
                                                            e,
                                                        )
                                                    }
                                                    className="form-control "
                                                    customInput={
                                                        <MaskedInput
                                                            pipe={
                                                                autoCorrectedDatePipe
                                                            }
                                                            mask={[
                                                                /\d/,
                                                                /\d/,
                                                                "/",
                                                                /\d/,
                                                                /\d/,
                                                                "/",
                                                                /\d/,
                                                                /\d/,
                                                                /\d/,
                                                                /\d/,
                                                            ]}
                                                            keepCharPositions={
                                                                true
                                                            }
                                                            guide={true}
                                                            showMask={false}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-right col-2 col-md-6 col-lg-3">
                            <div className="custom_fltr">
                                <a onClick={()=>this.handleFilterToggle()}><FilterIcon className="img-svg"></FilterIcon></a>
                                <FilterTooltip 
                                    handleFilterToggle={this.handleFilterToggle.bind(this)}
                                    filterModal={this.state.filterModal} 
                                    filterPoChase={this.state.filterPoChase}
                                    filterCreateDate={this.state.filterCreateDate}
                                    handleFilterRefreesh={this.handleFilterRefreesh.bind(this)}

                                />
                            </div>
                            <Link
                                className="btn btn-primary add_option"
                                to="/admin/booking/add">
                                <i className="fa fa-plus"></i> <span>Add</span>
                            </Link>
                            <ExportSheet
                                header={head}
                                fileName={"bookings"}
                                dataSource={this.state.dataXlsx}
                                xlsx={XLSX}>
                                <button className="btn btn-default add_print ml-3">
                                    <i className="fa fa-print"></i>
                                </button>
                            </ExportSheet>
                        </div>
                    </div>
                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>
                                    <th scope="col" width="200">
                                        Hospital Name
                                    </th>
                                    <th scope="col" className="mobilehide">
                                        Contact Person
                                    </th>
                                    <th scope="col" className="mobilehide">
                                        Sets
                                    </th>
                                    <th scope="col">Operation Name</th>
                                    <th scope="col">Important Dates</th>
                                    <th scope="col">Booked By</th>
                                    <th
                                        scope="col"
                                        className="text-right"
                                        width="185">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.newBookingList &&
                                    this.state.newBookingList.map((item,mainInd) =>
                                        item.map((innerItem, index) => (
                                            <React.Fragment
                                                key={innerItem.bookings._id}>
                                                {index == 0 && (
                                                    <tr
                                                        className="data_date"
                                                        key={
                                                            innerItem.bookings
                                                                ._id
                                                        }>
                                                        <th
                                                            colSpan="7"
                                                            className="text-center">
                                                            {moment(
                                                                moment(
                                                                    innerItem
                                                                        .bookings
                                                                        .working_delivery_date,
                                                                ).format(
                                                                    "YYYY-MM-DD",
                                                                ),
                                                            ).isSame(
                                                                this.state
                                                                    .todayDate,
                                                            )
                                                                ? "Today"
                                                                : moment(
                                                                      innerItem
                                                                          .bookings
                                                                          .working_delivery_date,
                                                                  ).format(
                                                                      "ddd, MMMM Do",
                                                                  )}
                                                        </th>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td
                                                        width="200"
                                                        data-th="Hospital Name"
                                                        className="mobilehide_heading">
                                                        <div className="">
                                                            <Link
                                                                to={
                                                                    "/admin/booking/details/" +
                                                                    innerItem
                                                                        .bookings
                                                                        ._id
                                                                }>
                                                                <span>
                                                                    <strong>
                                                                        <span
                                                                            style={{
                                                                                display:
                                                                                    "block",
                                                                            }}
                                                                            className="theme-text">
                                                                            {
                                                                                innerItem
                                                                                    .bookings
                                                                                    .booking_id
                                                                            }
                                                                        </span>
                                                                        {
                                                                            innerItem
                                                                                .hospitalId
                                                                                .name
                                                                        }
                                                                    </strong>
                                                                </span>
                                                                <br />
                                                                {innerItem
                                                                    .bookingSurgeonData
                                                                    .length >
                                                                    0 && (
                                                                    <React.Fragment>
                                                                        <span>
                                                                            <small>
                                                                                Surgeon:
                                                                            </small>{" "}
                                                                            <strong>
                                                                                {innerItem
                                                                                    .bookingSurgeonData[0]
                                                                                    .SurgeonDetail[0]
                                                                                    .first_name +
                                                                                    " " +
                                                                                    innerItem
                                                                                        .bookingSurgeonData[0]
                                                                                        .SurgeonDetail[0]
                                                                                        .last_name}
                                                                            </strong>
                                                                        </span>
                                                                        <br />
                                                                    </React.Fragment>
                                                                )}
                                                                <small className="mobilehide">
                                                                    Booked on{" "}
                                                                    {dateFormat(
                                                                        innerItem
                                                                            .bookings
                                                                            .createdAt,
                                                                        "ddd,mmm dS",
                                                                    )}
                                                                </small>
                                                               
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td
                                                        data-th="Contact Person"
                                                        className="mobilehide">
                                                        <div className="info-left">
                                                            <strong>
                                                                {
                                                                    innerItem
                                                                        .hospitalId
                                                                        .contact_person_name
                                                                }{" "}
                                                            </strong>
                                                            <br />
                                                            <small>
                                                                Location:{" "}
                                                                {
                                                                    innerItem
                                                                    .bookings
                                                                    .hospital_address
                                                                }{" "}
                                                            </small>
                                                            <br />
                                                            <small>
                                                                Email:{" "}
                                                                {
                                                                    innerItem
                                                                        .hospitalId
                                                                        .email
                                                                }
                                                            </small>
                                                            <br />
                                                            <small>
                                                                Phone:{" "}
                                                                {
                                                                    innerItem
                                                                        .hospitalId
                                                                        .contact_person_phone_number
                                                                }
                                                            </small>
                                                            <br />
                                                            <small>
                                                                Department:{" "}
                                                                {
                                                                    innerItem
                                                                        .bookings
                                                                        .hospital_department
                                                                }{" "}
                                                            </small>
                                                        </div>
                                                    </td>
                                                    <td
                                                        data-th="Sets"
                                                        className="mobilehide">
                                                        <small>
                                                            {innerItem.bookingKitSetId &&
                                                                innerItem.bookingKitSetId.map(
                                                                    (
                                                                        kset,
                                                                        ind,
                                                                    ) => (
                                                                        <React.Fragment
                                                                            key={
                                                                                ind
                                                                            }>
                                                                            {/* <div><a href="javascript:;" onClick={()=>this.handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div> */}
                                                                            <div>
                                                                                <span>
                                                                                    {
                                                                                        kset
                                                                                            .setId[0]
                                                                                            .set_name
                                                                                    }{" "}
                                                                                </span>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ),
                                                                )}
                                                        </small>
                                                    </td>
                                                    <td data-th="Operation Name">
                                                        {innerItem.bookings
                                                            .operation_name ===
                                                        "Other" ? (
                                                            <span>
                                                                {" "}
                                                                {
                                                                    innerItem
                                                                        .bookings
                                                                        .other_opration
                                                                }{" "}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {" "}
                                                                {
                                                                    innerItem
                                                                        .bookings
                                                                        .operation_name
                                                                }{" "}
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td
                                                        data-th="Important Dates"
                                                        className="mobilehide_heading">
                                                        <small>
                                                            {innerItem.bookings
                                                                .booking_type ==
                                                                "loan" && (
                                                                <div>
                                                                    <span className="mobilehide">
                                                                        Delivery
                                                                        Date:{" "}
                                                                        <strong>
                                                                            {dateFormat(
                                                                                innerItem
                                                                                    .bookings
                                                                                    .delivery_date,
                                                                                "ddd,mmm dS",
                                                                            )}{" "}
                                                                            {
                                                                                innerItem
                                                                                    .bookings
                                                                                    .delivery_date_slot
                                                                            }
                                                                        </strong>
                                                                        <br />
                                                                    </span>
                                                                    Operation
                                                                    Date:{" "}
                                                                    <strong>
                                                                        {dateFormat(
                                                                            innerItem
                                                                                .bookings
                                                                                .operation_date,
                                                                            "ddd, mmm dS",
                                                                        )}{" "}
                                                                        {
                                                                            innerItem
                                                                                .bookings
                                                                                .operation_date_slot
                                                                        }
                                                                    </strong>
                                                                    <br />
                                                                </div>
                                                            )}
                                                            {innerItem.bookings
                                                                .booking_type !=
                                                                "loan" && (
                                                                <div>
                                                                    <span className="mobilehide">
                                                                        Delivery
                                                                        Date:{" "}
                                                                        <strong>
                                                                            {dateFormat(
                                                                                innerItem
                                                                                    .bookings
                                                                                    .delivery_date,
                                                                                "ddd,mmm dS",
                                                                            )}
                                                                        </strong>
                                                                    </span>
                                                                    <br />
                                                                </div>
                                                            )}
                                                            <span className="mobilehide">
                                                                Collection Date:{" "}
                                                                <strong>
                                                                    {dateFormat(
                                                                        innerItem
                                                                            .bookings
                                                                            .collection_date,
                                                                        "ddd,mmm dS",
                                                                    )}
                                                                </strong>
                                                            </span><br />
                                                            <span className="mobilehide">

                                                                Create Date:{" "}
                                                                <strong>
                                                                    {dateFormat(
                                                                        innerItem
                                                                            .bookings

                                                                            .confirm_booking_date,
                                                                        "ddd,mmm dS",
                                                                    )}
                                                                </strong>
                                                            </span>
                                                        </small>
                                                    </td>
                                                    <td
                                                        data-th="Booked By"
                                                        className="mobilehide_heading">
                                                        {innerItem
                                                            .booked_by_user
                                                            .length > 0
                                                            ? innerItem
                                                                  .booked_by_user[0]
                                                                  .first_name +
                                                              " " +
                                                              innerItem
                                                                  .booked_by_user[0]
                                                                  .last_name
                                                            : "-"}
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="text-center">
                                                                <div>
                                                                    <span
                                                                        className={
                                                                            "mr-2 capsule " +
                                                                            (innerItem
                                                                                .bookings
                                                                                .status ==
                                                                            "booked"
                                                                                ? "green-dim "
                                                                                : "red-dim ")
                                                                           
                                                                        }>
                                                                        {innerItem
                                                                            .bookings
                                                                            .status ==
                                                                            "dispatch" && (
                                                                            <div>
                                                                                Dispatched
                                                                            </div>
                                                                        )}
                                                                        {innerItem
                                                                            .bookings
                                                                            .status !=
                                                                            "dispatch" &&
                                                                            this.capitalize(
                                                                                innerItem
                                                                                    .bookings
                                                                                    .status,
                                                                            )}
                                                                    </span>
                                                                    <span>
                                                                        <Link
                                                                            className="btn-link mr-2 desktophide"
                                                                            to={
                                                                                "/admin/booking/details/" +
                                                                                innerItem
                                                                                    .bookings
                                                                                    ._id
                                                                            }>
                                                                            More
                                                                            Details
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                                <div className="mt-2 small"  onMouseEnter={() => this.updateMouseEnter(innerItem,mainInd,index)} onMouseLeave={() => this.updateMouseLeave(innerItem,mainInd,index)}>
                                                                    {innerItem.bookings.po_hover !=undefined ?
                                                                        <small className="mobilehide">
                                                                            PO Number: <input type="text"  className="text-center" onChange={e =>this.updatePONumber(e,innerItem,mainInd,index)} value={innerItem.bookings.po_number} />
                                                                        </small>
                                                                        :
                                                                        <small className="mobilehide">
                                                                            PO Number: <input type="text" className="bg-hide text-center"  onChange={e =>this.updatePONumber(e,innerItem,mainInd,index)} value={innerItem.bookings.po_number==""?"TBC":innerItem.bookings.po_number} />
                                                                        </small>
                                                                    }
                                                                </div>
                                                                <div className="mt-2 small">
                                                                    <label className=" small align-middle">Chase PO Number &nbsp;</label>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            innerItem
                                                                                .bookings
                                                                                .po_check
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handlePOCheck(
                                                                                e,innerItem,mainInd,index
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="action-area dropdown">
                                                                <a
                                                                    className="dropdown-toggle"
                                                                    href="#"
                                                                    id="navbarDropdown"
                                                                    role="button"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <span className="dots">
                                                                        ...
                                                                    </span>
                                                                </a>
                                                                <div
                                                                    className="dropdown-menu navbarDropdownSize"
                                                                    aria-labelledby="navbarDropdown">
                                                                    <Link
                                                                        className="dropdown-item mobilehide"
                                                                        to={
                                                                            "/admin/booking/details/" +
                                                                            innerItem
                                                                                .bookings
                                                                                ._id
                                                                        }>
                                                                        More
                                                                        Details
                                                                    </Link>

                                                                    {innerItem?.bookingcollections?.length === 0 &&
                                                                     innerItem?.bookingdispatches?.length > 0 &&
                                                                    (
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to={
                                                                                "/admin/booking/edit/" +
                                                                                innerItem
                                                                                    .bookings
                                                                                    ._id
                                                                            }>
                                                                            Edit
                                                                        </Link>
                                                                    )}

                                                                    {innerItem
                                                                        .bookings
                                                                        .status ==
                                                                        "booked" &&
                                                                        dateFormat(
                                                                            innerItem
                                                                                .bookings
                                                                                .delivery_date,
                                                                            "yyyy-mm-dd",
                                                                        ) ==
                                                                            todayDate && (
                                                                            <Link
                                                                                className="dropdown-item"
                                                                                to={
                                                                                    "/admin/booking/details/" +
                                                                                    innerItem
                                                                                        .bookings
                                                                                        ._id
                                                                                }>
                                                                                Dispatch
                                                                            </Link>
                                                                        )}
                                                                   
                                                                    {innerItem
                                                                        .bookings
                                                                        .status ==
                                                                        "dispatch" && (
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to={
                                                                                "/admin/booking/item-used/o/" +
                                                                                innerItem
                                                                                    .bookings
                                                                                    ._id
                                                                            }>
                                                                            Item
                                                                            Used
                                                                        </Link>
                                                                    )}
                                                                    {innerItem
                                                                        .bookings
                                                                        .status ==
                                                                        "dispatch" && (
                                                                        <a
                                                                            href="javascript:;"
                                                                            onClick={() =>
                                                                                this.dateModalOpen(
                                                                                    innerItem,
                                                                                )
                                                                            }
                                                                            className="dropdown-item">
                                                                            Change
                                                                            Dates
                                                                        </a>
                                                                    )}

                                                                    {innerItem
                                                                        .bookings
                                                                        .status ===
                                                                        "booked" && (
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to={
                                                                                "/admin/booking/edit/" +
                                                                                innerItem
                                                                                    .bookings
                                                                                    ._id
                                                                            }>
                                                                            Edit
                                                                        </Link>
                                                                    )}

                                                                    {innerItem
                                                                        .bookings
                                                                        .status ==
                                                                        "booked" && (
                                                                        <a
                                                                            href="javascript:;"
                                                                            onClick={() =>
                                                                                this.cancelModalOpen(
                                                                                    innerItem
                                                                                        .bookings
                                                                                        ._id,
                                                                                )
                                                                            }
                                                                            className="dropdown-item">
                                                                            Cancel
                                                                        </a>
                                                                    )}

                                                                    {innerItem
                                                                        .bookings
                                                                        .status ==
                                                                        "dispatch" && (
                                                                        <a
                                                                            href="javascript:;"
                                                                            onClick={() =>
                                                                                this.repsUpdateModalOpen(
                                                                                    innerItem,
                                                                                )
                                                                            }
                                                                            className="dropdown-item">
                                                                            Assign Reps
                                                                        </a>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )),
                                    )}
                                {this.state.newBookingList &&
                                    Object.keys(this.state.newBookingList)
                                        .length == 0 &&
                                    loading == false && this.state.isLoading == false && (
                                        <tr className="text-center">
                                            <td colSpan="7">No Record Found</td>
                                        </tr>
                                )}
                            
                            </tbody>
                        </table>  
                    </div>
                </div>

                {this.state.newBookingList &&
                    this.state.newBookingList.length > 0 && (
                        <div className="">
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.limit}
                                totalItemsCount={this.state.totalItemsCount}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination justify-content-center mb-5"
                                activeLinkClass="active"
                                nextPageText="next"
                                prevPageText="prev"
                            />
                            <br className="d-block d-sm-none"/><br className="d-block d-sm-none"/>
                        </div>

                    )}
                <RepsUpdateModal bookingId = {this.state.bookingRepsUpdateId} isRepsUpdateModal = {this.state.repsUpdateModal} repsUpdateModalClose = {(flag)=>this.repsUpdateModalClose(flag)}/>

                <Modal
                    size="md"
                    backdrop="static"
                    isOpen={this.state.cancelModal}
                    toggle={() => this.cancelModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.cancelModalClose()}>
                        Cancel Booking
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group row">
                            <div className="col">
                                <label>
                                    Reason Of Cancellation{" "}
                                    <strong className="text-danger">*</strong>
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={this.state.delete_comment}
                                    onChange={e =>
                                        this.deleteCommentHandler(
                                            e.target.value,
                                        )
                                    }
                                />
                                {this.state.error == true && error ? (
                                    <span className="text-danger">
                                        {error.delete_comment
                                            ? error.delete_comment
                                            : ""}
                                    </span>
                                ) : (
                                    ""
                                )}
                                {this.state.error && error ? (
                                    <span className="text-danger">
                                        {error.common ? error.common : ""}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                onClick={() => this.cancelModalClose()}
                                className="btn btn-secondary mr-2"
                                data-dismiss="modal">
                                Cancel
                            </button>
                            {loading == false && (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.handleCancle()}>
                                    Ok
                                </button>
                            )}
                            {loading == true && (
                                <button
                                    className="btn btn-primary loader-icon"
                                    disabled>
                                    <Spinner className="img-svg"></Spinner>
                                </button>
                            )}
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}

                <Modal
                    size="ml"
                    backdrop="static"
                    isOpen={this.state.dispatchModal}
                    toggle={() => this.dispatchModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.dispatchModalClose()}>
                        Dispatch
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">
                            Please confirm if you want to dispatch this booking?
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                onClick={() => this.dispatchModalClose()}
                                className="btn btn-secondary mr-2"
                                data-dismiss="modal">
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={() => this.handleDispatch()}>
                                Ok
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Cancle Modal*/}

                <Modal
                    size="lg"
                    backdrop="static"
                    isOpen={this.state.kitSetDetailModal}
                    toggle={() => this.kitSetDetailModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.kitSetDetailModalClose()}>
                        Set Details
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.kitDetails.setProductModules &&
                                    this.state.kitDetails.setProductModules.map(
                                        (product, ind) => (
                                            <tr key={ind}>
                                                <td>
                                                    {product.productID.code}
                                                </td>
                                                <td>
                                                    {product.productID.name}
                                                </td>
                                                <td>{product.quantity}</td>
                                            </tr>
                                        ),
                                    )}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                className="btn btn-secondary"
                                onClick={() => this.kitSetDetailModalClose()}>
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Set Details Modal */}

                <Modal
                    size="ml"
                    backdrop="static"
                    isOpen={this.state.dateModal}
                    toggle={() => this.dateModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.dateModalClose()}>
                        Purchase/Hire Details
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="form-group">
                                <label>
                                    Delivery Date{" "}
                                    <strong className="text-danger">*</strong>
                                </label>
                                <Input
                                    type="date"
                                    id="datepicker"
                                    value={bokkingDate.delivery_date}
                                    // readonly={this.state.deliveryDateIsDisabled}
                                    disabled={this.state.deliveryDateIsDisabled}
                                    className="small"
                                    onChange={e=>this.handleDeliveryDate(e.target.value)}
                                />
                                {error && this.state.dateSubmit == 1 ? (
                                    <span className="text-danger">
                                        {error.delivery_date
                                            ? error.delivery_date
                                            : ""}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="form-group">
                                <label>
                                    Operation Date{" "}
                                    <strong className="text-danger">*</strong>
                                </label>
                                <Input
                                    type="date"
                                    id="datepicker"
                                    className="small "
                                    value={bokkingDate.operation_date}
                                    onChange={e =>
                                        this.handleOprationDate(e.target.value)
                                    }
                                />
                                {error && this.state.dateSubmit == 1 ? (
                                    <span className="text-danger">
                                        {error.operation_date
                                            ? error.operation_date
                                            : ""}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="form-group">
                                <label>
                                    Collection Date{" "}
                                    <strong className="text-danger">*</strong>
                                </label>
                                <Input
                                    type="date"
                                    id="datepicker"
                                    className="small "
                                    value={bokkingDate.collection_date}
                                    onChange={e =>
                                        this.handleCollectionDate(
                                            e.target.value,
                                        )
                                    }
                                />
                                {error && this.state.dateSubmit == 1 ? (
                                    <span className="text-danger">
                                        {error.collection_date
                                            ? error.collection_date
                                            : ""}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                onClick={() => this.dateModalClose()}
                                className="btn btn-secondary mr-2"
                                data-dismiss="modal">
                                Cancel
                            </button>
                            {loading == false && (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.handleUpdateDate()}>
                                    Update
                                </button>
                            )}
                            {loading == true && (
                                <button
                                    className="btn btn-primary loader-icon"
                                    disabled>
                                    <Spinner className="img-svg"></Spinner>
                                </button>
                            )}
                        </div>
                    </ModalFooter>
                </Modal>
                {/* date Modal */}

                <Modal
                    size="ml"
                    backdrop="static"
                    isOpen={this.state.itemUsedModal}
                    toggle={() => this.itemUsedModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.itemUsedModalClose()}>
                        Item Used
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Set Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.setList &&
                                    this.state.setList.map((item, index) => (
                                        <tr key={index}>
                                            <td className="align-top">
                                                <Input
                                                    type="checkbox"
                                                    className="ml-0"
                                                    onChange={e =>
                                                        this.updateSetStatus(
                                                            e.target.checked,
                                                            index,
                                                        )
                                                    }
                                                    checked={
                                                        item.item_in_used ==
                                                        true
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </td>
                                            <td>{item.setData[0].set_name}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                className="btn btn-primary"
                                onClick={() => this.itemUsedUpdate()}>
                                Update
                            </button>
                            <button
                                className="btn btn-secondary ml-2"
                                onClick={() => this.itemUsedModalClose()}>
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* item used Modal */}

                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        bookingList,
        activePage,
        totalItemsCount,
        limit,
        loading,
        redirect,
        error,
    } = state.rootReducer.bookings;
    const { refreshList } = state.rootReducer.hospitals;
    const { editSet, set } = state.rootReducer.sets;
    return {
        bookingList,
        error,
        refreshList,
        loading,
        activePage,
        totalItemsCount,
        limit,
        redirect,
        editSet,
        set,
    };
}

export default connect(mapStateToProps)(Index);
