import React, { createRef, Component } from "react";
import { connect } from "react-redux";
import Navbar from "../Navbar";
import { Redirect, Link } from "react-router-dom";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Select from "react-select";
import { Input } from "reactstrap";
import { bookingService } from "../../../../services";
import * as excelJs from "exceljs";
import * as xlsx from "xlsx";

import { authHeader,check419 } from "../../../../helpers";
import { history } from "../../../../helpers/";


const reviewOptions = [{ value: "satisfactory", label: "Satisfactory" },
{ value: "not_satisfactory", label: "Not Satisfactory" },
{ value: "other", label: "Other" },
{ value: "na", label: "NA" }
];

const importExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const dataFromXLSX = xlsx.utils.sheet_to_json(worksheet);
        transformData(dataFromXLSX);
    };
    reader.readAsArrayBuffer(file);
};


function transformData(originalData) {
    console.log("original", originalData);
    const user = JSON.parse(localStorage.getItem("user"));
    const dataRows = originalData.filter((row) => row["Add Item Used"] !== "Id").slice(0, -1);

    const transformedData = dataRows.map((row) => ({
        _id: row["Add Item Used"],
        item_in_used: row["__EMPTY_4"] === "Yes" || row["__EMPTY_4"] === "yes" ? true : false,
        quantity_of_used_item: row["__EMPTY_3"],
    }));

    const lastRow = originalData[originalData.length - 1];
    const review = lastRow["__EMPTY"];
    const description = lastRow["__EMPTY_1"];
    const bookingId = lastRow["Add Item Used"];
    const userId = user.data._id;
    const case_not_attended_tick = lastRow["__EMPTY_2"] === "Yes" || lastRow["__EMPTY_2"] === "yes" ? true : false;

    const result = {
        data: transformedData,
        description,
        review,
        bookingId,
        userId,
        case_not_attended_tick,
        fileImport : true
    };

    console.log("result", result);

    bookingService.updateItemUsedStatus(result)
        .then((res) => {
            console.log("ressssss", res);
            window.location.reload();
        })
        .catch((error) => {
            console.log(error);
        });
}

const generateXLSX = async (data, review, note, id, case_not_attended_tick) => {
    const workbook = new excelJs.Workbook();
    const ws = workbook.addWorksheet("Test Worksheet");
    const header1 = [
        "Id",
        "Outcome of the case",
        "Notes",
        "Case Not Attended"
    ];

    const rowData1 = [
        id,
        review,
        note,
        case_not_attended_tick == true ? "Yes" : "No"
    ];

    ws.protect({
        selectLockedCells: true,
        selectUnlockedCells: true,
        formatCells: false,
        formatColumns: false,
        formatRows: false,
        insertRows: false,
        insertHyperlinks: false,
        deleteRows: false,
        sort: false,
        autoFilter: false,
        pivotTables: false,
        objects: false,
        scenarios: false,
    });

    ws.mergeCells("A1:F1");
    const headingRow = ws.getCell("A1");
    headingRow.value = "Add Item Used";
    headingRow.alignment = { horizontal: "center" };

    const header2 = [
        "Id",
        "Product Code",
        "Product Description",
        "Brand",
        "Quantity",
        "Used/Implanted",
    ];
    const header1Row = ws.addRow(header2);
    header1Row.eachCell((cell) => {
        cell.font = {
            bold: true,
            size: 11,
        };
    });

    if (data?.length > 0) {
        data.map(item => {

            const rowData = [
                item._id,
                item.manufacturers_product_code,
                item.name,
                item.product_brand,
                parseInt(item.quantity_of_used_item),
                item.item_in_used == true ? "Yes" : "No",
            ];

            const header1Data = ws.addRow(rowData);
            header1Data.eachCell((cell, colNumber) => {
                if (colNumber === 5 || colNumber === 6) {
                    cell.protection = {
                        locked: false,
                    };
                }
                if (cell.value === "Yes" || cell.value === "No") {
                    const dropdownValues = ["Yes", "No"];
                    cell.value = item.item_in_used == true ? "Yes" : "No";
                    cell.dataValidation = {
                        type: "list",
                        allowBlank: false,
                        formulae: [`"${dropdownValues.join(",")}"`],
                        showDropDown: false,
                        showDropDownInCell: true
                    };
                }
            });

        });
    }

    ws.addRow();
    const header2Row = ws.addRow(header1);
    header2Row.eachCell((cell) => {
        cell.font = {
            bold: true,
            size: 11,
        };
    });

    const header2Data = ws.addRow(rowData1);
    header2Data.eachCell((cell, colNumber) => {
        if (colNumber === 2 || colNumber === 3 || colNumber === 4) {
            cell.protection = {
                locked: false,
            };
        }
        if (cell.value === "satisfactory" || cell.value === "not_satisfactory" || cell.value === "other" || cell.value === "na") {
            const dropdownValues = ["satisfactory", "not_satisfactory", "other","na"];
            cell.value = review;
            cell.dataValidation = {
                type: "list",
                showDropDown: true,
                formulae: [dropdownValues.join(",")],
            };
        }
        if (cell.value === "Yes" || cell.value === "No") {
            const dropdownValues = ["Yes", "No"];
            cell.value = case_not_attended_tick == true ? "Yes" : "No";
            cell.dataValidation = {
                type: "list",
                allowBlank: false,
                formulae: [`"${dropdownValues.join(",")}"`],
                showDropDown: false,
                showDropDownInCell: true
            };
        }
    });


    ws.columns.forEach((col) => (col.width = 20));

    ws.eachRow((row, rowNumber) => {
        row.eachCell((cell) => {
            cell.border = {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            };
            cell.alignment = { horizontal: "center" };
        });
        if (rowNumber === 1) {
            row.eachCell((cell) => {
                cell.font = {
                    bold: true,
                    size: 11,
                };
            });
        }
    });

    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
        new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    );

    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "item-used";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);

};

class ItemUsed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            setList: [],
            id: "",
            selectedReviewOption: [],
            selectedReview: "",
            case_not_attended_tick: false,
            note: "",
            redirect: "",
            from: "",
            searchProducts: "",
            productList: [],
            error: "",
            selectedProductList: [],
            suggestionsActive: false,
            suggestionIndex: 0,
            generationInProgress: false,
            dataXlsx: [],
            showImportModal: false,
            bookingSetList:[]
        };

        this.fileInputRef = createRef();
        this.listInnerRef = createRef();

        this.getList = this.getList.bind(this);
        this.setSelectedReviewOption = this.setSelectedReviewOption.bind(this);
        this.setNote = this.setNote.bind(this);
        this.setCaseNotAttended = this.setCaseNotAttended.bind(this);
        this.updateProductStatus = this.updateProductStatus.bind(this);
        this.submitItemUsed = this.submitItemUsed.bind(this);
        this.handleOnCustomProductSearch = this.handleOnCustomProductSearch.bind(this);
        this.handleProductList = this.handleProductList.bind(this);
        this.handleOnProductSelect = this.handleOnProductSelect.bind(this);
        this.handleProductQuantity = this.handleProductQuantity.bind(this);
        this.handleExportClick = this.handleExportClick.bind(this);
        this.getBookingkitList = this.getBookingkitList.bind(this);
        this.handleHireCharge = this.handleHireCharge.bind(this);
    }

    openImportModal = () => {
        this.setState({ showImportModal: true });
    };

    handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            importExcel(file);
        }
        this.setState({ showImportModal: false });
    };

    handleExportClick(data) {
        this.setState(
            {
                generationInProgress: true,
            });

        console.log("datatataa", data, generateXLSX);

        generateXLSX(data)
            .then(() => {
                this.setState(
                    {
                        generationInProgress: false,
                    });
            })
            .catch((error) => {
                console.error("Error generating Excel:", error);
                this.setState(
                    {
                        generationInProgress: false,
                    });
            });
    }

    getList(id) {
        bookingService.getSetList(id).then((res) => {
            var selectedReview = [];
            selectedReview.push(reviewOptions.find(val => val.value === res.review));

            if (res.data.length != 0) {
                var data = res.data;

                data.map((item) => {
                    item._id = item.productId;
                });

                res.data = data;
            }
            console.log("res", res.data);
            this.setState({
                selectedProductList: res.data,
                selectedReviewOption: selectedReview,
                selectedReview: res.review,
                note: res.description,
                case_not_attended_tick: res.case_not_attended_tick
            });
        });
    }

    getBookingkitList(id) {
        
        let payload = {
            bookingId: id
        };

        bookingService.getBookingkitSetList(payload).then((res) => {
            this.setState({
                bookingSetList: res.data
            });
        });
    }

    handleOnCustomProductSearch(e) {
        let searchString = e.target.value;
        console.log("string", searchString);
        this.setState({
            searchProducts: searchString,
            page: 1
        }, () => { this.getSearch(); });

        //this.props.dispatch(setActions.getProductsList({ search: searchString }));
    }

    getSearch() {

        const requestOptions = {
            method: "POST",
            headers: { ...authHeader(),"Content-Type": "application/json" },
            body: JSON.stringify({ search: this.state.searchProducts, page: this.state.page })
        };

        fetch(process.env.REACT_APP_APIURL + "products/searchProducts", requestOptions)
            .then(response => response.json())
            .then(response => {
                if(response.status === 419){
                    check419();
                }else{
                    this.handleProductList(response.data);
                }
                
            });

    }

    handleProductList(productList) {

        productList.map((item) => {
            item.nameOld = item.name;
            item.name = item.manufacturers_product_code + ", " + item.name + ", " + item.product_brand + ", " + item.code + "";
            item.item_in_used = true;
        });

        if (this.state.page > 1 && productList.length > 0) {
            let oldList = this.state.productList;
            let newProductList = oldList.concat(productList);
            this.setState({
                productList: newProductList,
                suggestionsActive: true
            });
        }
        else {
            if (this.state.page == 1 || productList.length > 0) {
                this.setState({
                    productList: productList,
                    suggestionsActive: true
                });
            }
        }
    }

    handleOnProductSelect(item) {

        item.name = item.nameOld;
        var id = item._id;
        var newProduct;
        var index;

        if (this.state.selectedProductList.length > 0) {
            newProduct = this.state.selectedProductList.find(product => product._id === id);
            index = this.state.selectedProductList.findIndex(product => product._id === id);
        }

        if (newProduct) {
            newProduct.quantity_of_used_item = parseInt(newProduct.quantity_of_used_item) + 1;
            var list = this.state.selectedProductList;
            list[index] = newProduct;

            this.setState({
                selectedProductList: list,
            });
        } else {
            item.quantity_of_used_item = 1;
            this.setState({
                selectedProduct: item
            });

            this.setState(prevState => ({
                selectedProductList: [item, ...prevState.selectedProductList]
            }));
        }

        this.setState({
            productList: [],
            searchProducts: "",
            suggestionsActive: false
        });


    }

    handleProductQuantity(quantity, index) {
        var selectedProductList = this.state.selectedProductList;
        const inputValue = parseInt(quantity) || 0;
        const min = 0;
        
        if (inputValue < min) {
            selectedProductList[index].quantity_of_used_item = min;
        }  else {
            selectedProductList[index].quantity_of_used_item = inputValue.toString();
        }

        this.setState({
            selectedProductList: selectedProductList,
        });
    }

    deleteProduct(e, id) {
        e.preventDefault();
        if (this.state.case_not_attended_tick && this.state.from === "o") {
            return true;
        } if (this.state.case_not_attended_tick && this.state.from === "c") {
            return true;
        }else {
            const newProductList = this.state.selectedProductList.filter(product => product._id !== id);
            this.setState({ selectedProductList: newProductList });
        }
    }

    setSelectedReviewOption(e) {
        var selectedReview = [];
        selectedReview.push({ value: e.value, label: e.label });
        // if(e.value != "other") {
        //     this.setState({
        //         note: ""
        //     });
        // }
        this.setState({
            selectedReviewOption: selectedReview,
            selectedReview: e.value,
            note: ""
        });
    }

    setNote(val) {
        this.setState({
            note: val
        });
    }

    setCaseNotAttended(val) {
        console.log("nnnnnnnn", val);
        this.setState({
            case_not_attended_tick: val
        });
    }

    updateProductStatus(val, index) {
        let selectedProductList = this.state.selectedProductList;
        selectedProductList[index].item_in_used = val;

        this.setState({
            selectedProductList: selectedProductList
        });
    }

    // handleQuantity(val, index) {
    //     let setList = this.state.setList;
    //     setList[index].quantity_of_used_item = val;
    //     this.setState({
    //         setList: setList
    //     });
    // }

    submitItemUsed(flag) {
        let data = [];
        let selectedProductList = this.state.selectedProductList;

        let hireData = [];
        let bookingSetList = this.state.bookingSetList;

        bookingSetList.map((item) => {
            hireData.push({ id: item._id, hire_charge: Math.abs(item.hire_charge) });
        });

        selectedProductList.map((item) => {
            data.push({ _id: item._id, item_in_used: item.item_in_used, quantity_of_used_item: Math.abs(item.quantity_of_used_item) });
        });

        // if (!this.state.case_not_attended_tick) {
        bookingService.updateItemUsedStatus({
            data: data, 
            description: this.state.note,
            review: this.state.selectedReview, 
            bookingId: this.state.id, 
            userId: this.state.user.data._id,
            case_not_attended_tick: this.state.case_not_attended_tick,
            email_send: flag,
            bookingKitSetsData:hireData,
            fileImport:false
        }).then((res) => {
            this.setState({
                redirect: res.status
            });
        }).catch((error) => {
            this.setState({
                error: error
            });
        });
    }
    // }


    handleHireCharge(value, index) {
        var bookingSetList = this.state.bookingSetList;
        const inputValue = parseInt(value) || 0;
        const min = 0;
        
        if (inputValue < min) {
            bookingSetList[index].hire_charge = min;
        }  else {
            bookingSetList[index].hire_charge = inputValue.toString();
        }

        this.setState({
            bookingSetList: bookingSetList,
        });
    }


    
    onScroll = () => {
        if (this.listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = this.listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                // TO SOMETHING HERE
                console.log("Reached bottom");

                let page = Number(this.state.page) + 1;

                this.setState({
                    page: page
                }, () => { this.getSearch(); });
            }
        }
    };

    componentDidMount() {
        this.setState(
            {
                id: this.props.match.params.id,
                from: this.props.match.params.f,
            },
            () => {
                this.getList(this.state.id);
                this.getBookingkitList(this.state.id);
            }
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.productsList) {
            this.handleProductList(nextProps.productsList);
        }
    }

    render() {
        if (this.state.redirect === 200) {
            return <Redirect to="/admin/bookings" />;
        }
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        //const { error } = this.props;
        const isCaseNotAttendedAndNoData = this.state.case_not_attended_tick && this.state.selectedProductList.length === 0;

        return (
            <div>
                <Navbar activePage="bookings" />
                <div className="container">
                    <h1 className="date-scroll pt-4">
                        <a onClick={()=>history.goBack()}>
                            <i className="fa fa-long-arrow-left smbtn"></i> <strong>Back</strong>
                        </a>

                        <div className="float-right mr-3">
                            <div className="row">
                                <div className="mr-2 mb-0 mt-2 mr-3">
                                    <Input
                                        type="checkbox"
                                        className="form-check-input mt-1"
                                        checked={this.state.case_not_attended_tick}
                                        value={this.state.case_not_attended_tick}
                                        onChange={(e) => this.setCaseNotAttended(e.target.checked)}
                                    />
                                    <h6 className="mb-0 p-0">Case Not Attended</h6>
                                </div>

                                {/* <label className="btn btn-default add_option mr-2 mb-0">
                                    Import
                                    <input
                                        type="file"
                                        accept=".xlsx"
                                        ref={this.fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={this.handleFileSelect}
                                    />
                                </label> */}



                                <label className={`btn btn-default add_option mr-2 mb-0 ${this.state.case_not_attended_tick ? "disabled" : ""}`}>
                                    Import
                                    <input
                                        type="file"
                                        accept=".xlsx"
                                        ref={this.fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={this.handleFileSelect}
                                        disabled={this.state.case_not_attended_tick}
                                    />
                                </label>

                                {/* Import Modal */}
                                {this.state.showImportModal && (
                                    <div className="modal" tabIndex="-1" role="dialog">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Import Data</h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        onClick={() => this.setState({ showImportModal: false })}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        onClick={() => this.setState({ showImportModal: false })}
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.generationInProgress ? (
                                    <p>Loading...</p>
                                ) : (
                                    <button type="button" onClick={() => generateXLSX(this.state.selectedProductList, this.state.selectedReview, this.state.note, this.state.id, this.state.case_not_attended_tick)} className="btn btn-default add_print"> Export </button>
                                )}
                            </div>
                        </div>

                    </h1>
                    <div className="row pt-4 mobile_nocard">
                        <div className="col-lg-12">
                            <div className="d-card">
                                <div className="form-group">


                                    {this.state.from === "o" ?
                                        <label><strong>Add Item Used</strong></label>
                                        :
                                        <label><strong>Item Used</strong></label>
                                    }

                                    <div className="autocomplete">
                                        <div className="wrapper">
                                            <div className="sc-hKMtZM">
                                                <svg className="sc-bczRLJ jCRvWa search-icon" width="20" height="20" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
                                                    </path>
                                                </svg>
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    className="form-control"
                                                    value={this.state.searchProducts}
                                                    onChange={(e) => this.handleOnCustomProductSearch(e)}
                                                    disabled={this.state.case_not_attended_tick ? true : false}

                                                />

                                                {this.state.suggestionsActive && this.state.searchProducts != "" &&
                                                    <ul className="suggestions" onScroll={() => this.onScroll()} ref={this.listInnerRef}>

                                                        {this.state.productList.map((item, index) =>
                                                            <li
                                                                className={index === this.state.suggestionIndex ? "active" : ""}
                                                                key={index}
                                                                onClick={() => this.handleOnProductSelect(item)}
                                                            >
                                                                <i className="fa fa-search suggestionsSearch"></i>
                                                                <span className="suggestionsText">{item.name}</span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                }
                                            </div>
                                            {this.state.error ? <span className="text-danger mt-2">{this.state.error?.common}</span> : ""}
                                        </div>
                                    </div>
                                    <div id="" className="table-responsive">
                                    <table className="table mt-1">
                                        <thead>
                                            <tr>
                                                <th>Product Code</th>
                                                <th>Product Description</th>
                                                <th>Brand</th>
                                                <th>Quantity</th>
                                                <th>Used/Implanted?</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.selectedProductList.length > 0 && this.state.selectedProductList.map((product, index) => (
                                                <tr key={index}>
                                                    {/* <td>{product.code}</td> */}
                                                    <td>{product.manufacturers_product_code}</td>
                                                    <td>{product.name}</td>
                                                    <td>{product.product_brand}</td>
                                                    <td className="align-items-start">
                                                        {this.state.from === "o" ?
                                                            <input
                                                                type="number"
                                                                className="form-control sml-input"
                                                                value={product.quantity_of_used_item}
                                                                min="0"
                                                                onChange={(e) => this.handleProductQuantity(e.target.value, index)}
                                                                disabled={this.state.case_not_attended_tick ? true : false}
                                                            />
                                                            :   <input
                                                                    type="number"
                                                                    className="form-control sml-input"
                                                                    value={product.quantity_of_used_item}
                                                                    min="0"
                                                                    onChange={(e) => this.handleProductQuantity(e.target.value, index)}
                                                                    disabled={this.state.case_not_attended_tick ? true : false}
                                                                />
                                                        }
                                                    </td>
                                                    <td>
                                                        {this.state.from === "o" ?
                                                            // <input
                                                            //     type = "checkbox"
                                                            //     className = "ml-0"
                                                            //     onChange = {(e) => this.updateProductStatus(e.target.checked, index)}
                                                            //     checked = { product.item_in_used == true ?  true : false }
                                                            // />
                                                            // :
                                                            // <input
                                                            //     type = "checkbox"
                                                            //     className = "ml-0"
                                                            //     readOnly
                                                            //     checked = { product.item_in_used == true ?  true : false }
                                                            // />
                                                            <div style={{ pointerEvents: `${this.state.case_not_attended_tick ? "none" : "inherit"}` }} >
                                                                <BootstrapSwitchButton
                                                                    checked={product.item_in_used == true ? true : false}
                                                                    onstyle="primary"
                                                                    offstyle="secondary"
                                                                    size="sm"
                                                                    width={70}
                                                                    onlabel="Yes"
                                                                    offlabel="No"
                                                                    onChange={(val) => this.updateProductStatus(val, index)}
                                                                />
                                                            </div>
                                                            :
                                                            <div style={{ pointerEvents: `${this.state.case_not_attended_tick ? "none" : "inherit"}` }} >
                                                                <BootstrapSwitchButton
                                                                    checked={product.item_in_used == true ? true : false}
                                                                    //disabled
                                                                    onlabel="Yes"
                                                                    offlabel="No"
                                                                    onstyle="primary"
                                                                    offstyle="secondary"
                                                                    size="sm"
                                                                    width={70}
                                                                    onChange={(val) => this.updateProductStatus(val, index)}
                                                                />
                                                            </div>
                                                        }
                                                    </td>
                                                    <td><a href="#" onClick={(e) => this.deleteProduct(e, product._id)}><i className="fa fa-trash"></i> </a></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-4 mobile_nocard">
                        <div className="col-lg-12">
                            <div className="d-card">
                                <h4 className="mb-2">Outcome of the case  <span className="text-danger">*</span></h4>
                                <div className="form-group">
                                    {this.state.from === "o" ?
                                        <Select
                                            value={this.state.selectedReviewOption}
                                            onChange={this.setSelectedReviewOption}
                                            options={reviewOptions}
                                            isDisabled={this.state.case_not_attended_tick ? true : false}
                                        />
                                        :
                                        <Select
                                            value={this.state.selectedReviewOption}
                                            onChange={this.setSelectedReviewOption}
                                            //isDisabled={true}
                                            options={reviewOptions}
                                            isDisabled={this.state.case_not_attended_tick ? true : false}
                                        />
                                    }
                                    {this.state.error ? (
                                        <span className="text-danger">
                                            {this.state.error?.review}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <h4 className="mb-2">Please specify in notes</h4>
                                <div className="form-group">
                                    {this.state.from === "o" ?
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            placeholder="Description"
                                            value={this.state.note}
                                            onChange={(e) => this.setNote(e.target.value)}
                                            disabled={this.state.case_not_attended_tick ? true : false}
                                        ></textarea>
                                        :
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            placeholder="Description"
                                            value={this.state.note}
                                            disabled={this.state.case_not_attended_tick ? true : false}
                                            onChange={(e) => this.setNote(e.target.value)}
                                            //disabled
                                        ></textarea>
                                    }
                                </div>
                            </div>

                            <div className="d-card mt-5 mobile_nocard"> 
                            <h4 className="mb-2">Purchase/Hire Details</h4>
                                <table className="table mt-1">
                                    <thead>
                                        <tr>
                                            <th style={{opacity:1}}>Kit Name</th>
                                            <th style={{opacity:1}}>Set Name</th>
                                            <th style={{opacity:1}}>Hire Charges (Per Set)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.bookingSetList.length > 0 && this.state.bookingSetList.map((setd, index) => (
                                        <tr key={index}>
                                            <td>{setd?.kitId[0].name}</td>
                                            <td>{setd?.setId[0].set_name}</td>
                                            <td>
                                                <div>
                                                        <Input
                                                            type="number"
                                                            min="0"
                                                            style={{width:"60%"}}
                                                            className="form-control"
                                                            value={setd.hire_charge}
                                                            onChange={e =>
                                                                this.handleHireCharge(
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                            name=""
                                                            placeholder="£"
                                                        />
                                                </div>
                                                
                                            </td>
                                        </tr>
                                    ))}
                                    
                                   

                                    </tbody>
                                </table>
                            </div>

                            <div className="mt-5 text-right">
                                <Link to="/admin/bookings" className="btn btn-secondary mb-3 mr-2" >Cancel</Link>
                                {this.state.from === "o" &&
                                !isCaseNotAttendedAndNoData ?
                                    <button className="btn btn-primary mb-3 mr-2"  onClick={() => this.submitItemUsed(false)}>Save</button>
                                :
                                    <button className="btn btn-primary mb-3 mr-2"  onClick={() => this.submitItemUsed(false)}>Save</button>
                                }

                                {!isCaseNotAttendedAndNoData && this.state.from === "o" && (
                                    <button className="btn btn-primary mb-3"  onClick={() => this.submitItemUsed(true)}>Save & Submit</button>
                                )}

                                {!isCaseNotAttendedAndNoData && this.state.from === "c" && (
                                    <button className="btn btn-primary mb-3"  onClick={() => this.submitItemUsed(true)}>Save & Submit</button>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { productsList } = state.rootReducer.sets;

    return {
        productsList
    };
}

export default connect(mapStateToProps)(ItemUsed);

