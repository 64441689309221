import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input, Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DashboardSidebar from "./NewDashboardSidebar";
import dateFormat from "dateformat";
import moment from "moment";
// import * as XLSX from "xlsx";
// import { ExportSheet } from "react-xlsx-sheet";
import { bookingRequestService } from "../../../../services/bookingRequest.services";
import { bookingService } from "../../../../services/booking.services";
import jsPDF from "jspdf";
import "jspdf-autotable";

import DispatchDetails from "./Model/DispatchDetails";
import AddDispatch from "./Model/AddDispatch";
import DatePicker from "react-datepicker";
import * as excelJs from "exceljs";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { debounce } from "lodash";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;

// const head = [
//     { title: "BOOKING", dataIndex: "booking" },
//     { title: "HOSPITAL NAME", dataIndex: "hospital_name" },
//     { title: "SET", dataIndex: "set" },
//     { title: "CHECKED", dataIndex: "checked" },
//     { title: "BOXED", dataIndex: "box" },
// ];

const options = [
    { label: "All Aces", value: "All Aces" },
    { label: "APC", value: "APC" },
    { label: "Sameday (HBC)", value: "Sameday (HBC)" },
    { label: "Other", value: "Other" },
];

const boxOptions = [
    { label: "Black Box", value: "Black Box" },
    { label: "Blue Box", value: "Blue Box" },
    { label: "Grey case", value: "Grey case" },
    { label: "Cardboard Box", value: "Cardboard Box" }
];

const dispatchedByOptions = [
    { label: "Lily Rose Winters", value: "Lily Rose Winters" },
    { label: "Ekaterina Karateodorova", value: "Ekaterina Karateodorova" },
    { label: "George Viziteu", value: "George Viziteu" },
    { label: "Ana Marie Sendrea", value: "Ana Marie Sendrea" },
    { label: "Jeff Allen", value: "Jeff Allen" },
    { label: "Others", value: "Other" }
];

const NewAdminBookingDispatch = () => {
    // const [dataXlsx, setDataXlsx] = useState([]);
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");
    const [search, setSearch] = useState("");
    const [filterDeliveryToDate, setfilterDeliveryToDate] = useState(todayDate);
    const [filterDeliveryFromDate, setfilterDeliveryFromDate] = useState(todayDate);
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState("");
    const [limit, setLimit] = useState(50);
    const [loading, setLoading] = useState(false);

    const [dayActive, setDateActive] = useState("today");
    const [bookingList, setBookingList] = useState([]);
    const [detailsModal,setDetailsModal] = useState(false);
    const [detailsModalResult, setDetailsModalResult] = useState([]);
    const [dispatchModal,setDispatchModal] = useState(false);
    const [bookingId,setBookingId] = useState(0);
    const [dispatch,setDispatch] = useState({
        courier: "All Aces",
        dispatchBoxesData: [{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }],
        tracker: "",
        bookingId: "",
        log_status: "dispatched",
        log_by_id: "",
        notes: "",
        dispatched_by:"Lily Rose Winters",
        other_dispatched_by:""
        
    });
    const [boxes,setBoxes] = useState([{box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false}]);
    const [submitFlag,setSubmitFlag] = useState(0);
    const [selectedDispatchedByValue,setSelectedDispatchedByValue] = useState("");
    const [otherDispatchedFlag,setOtherDispatchedFlag] = useState("");

    const [selectedOption,setSelectedOption] = useState([{ label: "All Aces", value: "All Aces" }]);
    const [selectedDispatchedByOptions,setSelectedDispatchedByOptions] = useState([{ label: "Lily Rose Winters", value: "Lily Rose Winters" }]);
    const [selectedNumOfBox,setSelectedNumOfBox] = useState([{ label: "Black Box", value: "Black Box" }]);
    const [error,setError] = useState({});
    const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")));

    const [searchDeliveryToDate, setSearchDeliveryToDate] = useState(newDate);
    const [searchDeliveryFromDate, setSearchDeliveryFromDate] = useState(newDate);
    const [listRefresh,setListRefresh] = useState(false);

    const handleSearch = (val) => {
        setSearch(val);
        setActivePage(1);
        debouncedSearch(val);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setLoading(true);
        // getList();
    };

    const handlePageStates = (activePage, totalItemsCount, limit) => {
        if (totalItemsCount === undefined) {
            totalItemsCount = 0;
        }
        setActivePage(activePage);
        setTotalItemsCount(totalItemsCount);
        setLimit(limit);
    };

    // const showXlsData = async () => {
    //     var dataXlsx = [];
    //     var kitset = "";
    //     bookingList && bookingList.map(item => {
    //         kitset = "";
    //         item.bookingKitSetId &&
    //             item.bookingKitSetId.map(kset => {
    //                 if (kitset != "") {
    //                     kitset += "\n" + kset.setId[0].set_name;
    //                 } else {
    //                     kitset += kset.setId[0].set_name;
    //                 }
    //             });

    //         dataXlsx.push({
    //             booking: item.bookings.po_number,
    //             hospital_name: item.hospitalId.name,
    //             set: kitset,
    //             checked:"",
    //             box: "",
    //         });
    //     });
    //     setDataXlsx(dataXlsx);
    // };

    const toDay = () => {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
            }${separator}${date < 10 ? `0${date}` : `${date}`}`;
        setfilterDeliveryToDate(todayDate);
        setfilterDeliveryFromDate(todayDate);

        setSearchDeliveryToDate(newDate);
        setSearchDeliveryFromDate(newDate);

        setActivePage(1);
        setDateActive("today");
        //getList(todayDate,todayDate);
    };

    const nextDays = () => {
        let newDate = new Date();
        let date = newDate.getDate() + 1;
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const tomorrowDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
            }${separator}${date < 10 ? `0${date}` : `${date}`}`;

        const searchTomorrowDate = new Date(tomorrowDate);

        setfilterDeliveryToDate(tomorrowDate);
        setfilterDeliveryFromDate(tomorrowDate);

        setSearchDeliveryToDate(searchTomorrowDate);
        setSearchDeliveryFromDate(searchTomorrowDate);

        //getList(tomorrowDate,tomorrowDate);
        setActivePage(1);
        setDateActive("tomorrow");
    };

    const pastBookings = () => {
        var pastYearDate = moment().subtract(6, "months").format("YYYY-MM-DD");
        var yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");

        const searchFromDate = new Date(pastYearDate);
        const searchToDate = new Date(yesterdayDate);

        setfilterDeliveryToDate(yesterdayDate);
        setfilterDeliveryFromDate(pastYearDate);

        setSearchDeliveryToDate(searchToDate);
        setSearchDeliveryFromDate(searchFromDate);

        setActivePage(1);
        setDateActive("past");
        //getList(pastYearDate,yesterdayDate);
    };


    const getList = async (deliveryDate,toDeliveryDate,searchTerm,currentPage) => {
        setBookingList([]);
        setLoading(true);
        try {
            const res = await bookingRequestService.getList({
                bookedByFilter: "",
                hospitalFilter: "",
                type: ["loan"],
                status: ["booked", "dispatch"],
                deliveryDateFiter: deliveryDate,
                toDeliveryDateFiter: toDeliveryDate,
                search: searchTerm != undefined ? searchTerm : search,
                page: currentPage != undefined ? currentPage : activePage,
                limit: limit,
            });
            setBookingList(res.data);
            setTotalItemsCount(res.total_count);
            setLoading(false);
            setListRefresh(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        // showXlsData();
        setUser(JSON.parse(localStorage.getItem("user")));
    }, [bookingList]);

    useEffect(() => {
        handlePageStates(activePage, totalItemsCount, limit);
    }, [activePage, totalItemsCount, limit]);

    useEffect(() => {
        getList(filterDeliveryFromDate,filterDeliveryToDate);
    }, [activePage,dayActive]);

    useEffect(() => {
        if(listRefresh){
            getList(filterDeliveryFromDate,filterDeliveryToDate);
        }
    }, [listRefresh]);

    

    const formatSets = (sets) => {
        // Join set names with line breaks
        return sets.map(set => `${set}`).join("\n");
    };

    const formatLines = (lines) => {
        // Join line names with line breaks
        return lines.map(line => `${line}`).join("\n");
    };

    const handleGeneratePdf = async () => {
        // const { bookingList } = this.props;
        let mainItem = bookingList.length ? bookingList.length : 0;

        const pdfData = mainItem ? bookingList.map((item) => {
            let obj = {};
            let setArray = [];
            let checkedLineArray = [];
            obj.booking_id = item?.bookings?.booking_id;
            obj.reps_name = item?.assigned_rep?.first_name + " " + item?.assigned_rep?.last_name;
            obj.hospital_name = item?.hospitalId?.name;
            obj.checkout = "________________";
            item.bookingKitSetId &&
                item.bookingKitSetId.map((kset) => {
                    setArray.push(kset.setId[0].set_name);
                    checkedLineArray.push("________________");
                });
            obj.sets = setArray;
            obj.checkLines = checkedLineArray;
            return obj;
        }) : [];

        let dateInfo = "";
        let dayActiveString = dayActive === "past" ? "past booking " : dayActive + " ";
        
        //let dateInfo = dayActive === "past" ? "from "+ moment(filterDeliveryFromDate).format("DD/MM/YYYY") + " to "+ moment(filterDeliveryToDate).format("DD/MM/YYYY"): moment(filterDeliveryFromDate).format("DD/MM/YYYY");
        
        if(dayActive === "past"){
            let dateTo = "";
            let dateFrom = "";

            if(filterDeliveryFromDate != ""){
                dateFrom = "from "+ moment(filterDeliveryFromDate).format("DD/MM/YYYY")+" ";
            }else{
                dateFrom = "";
            }
            
            if(filterDeliveryToDate != ""){
                dateTo = "to "+ moment(filterDeliveryToDate).format("DD/MM/YYYY")+" ";
            }else{
                dateTo = "";
            }

            dateInfo = dateFrom + dateTo;

        }else{
            dateInfo = moment(filterDeliveryFromDate).format("DD/MM/YYYY");
        }

        let headingContent =  ["Dispatch "+ dayActiveString + dateInfo];

        const pdfDoc = new jsPDF();

        const columns = ["BOOKING", "REPS NAME", "HOSPITAL NAME", "SET", "CHECKED"];

        pdfData.forEach((record, index) => {
            if (index > 0) {
                pdfDoc.addPage(); // Add a new page for each record after the first one
                // pdfDoc.setFontSize(14);
                // pdfDoc.text( 14, 10,headingContent);
            }else{
                // pdfDoc.setFontSize(14);
                // pdfDoc.text( 14, 10,headingContent);
            }

            const header = columns.map((col) => ({ title: col, dataKey: col.toLowerCase() }));
            const body = [[record.booking_id, record.reps_name, record.hospital_name, formatSets(record.sets), formatLines(record.checkLines)]];

            pdfDoc.autoTable({
                head: [headingContent],
            });

            pdfDoc.autoTable({
                head: [header], body, didDrawPage: didDrawPageCallback, columnStyles: {
                    0: { halign: "center" },
                    1: { halign: "center" },
                    2: { halign: "center" },
                    3: { halign: "center", overflow: "ellipsize", minCellWidth: 80 },
                    4: { halign: "center" }
                },
                headStyles: {
                    halign: "center"
                },
                margin: { top: 0 }
            });
        });

        pdfDoc.save("booking-dispatch.pdf");
    };

    const didDrawPageCallback = (data) => {
        // Draw a border around the table on each page
        const { doc, settings } = data;
        const startX = settings.margin.left;
        const startY = settings.margin.top+29;
        const columnWidths = data.table.columns.map((col) => col.width);
        const rowHeight = data.table.body[0].height;
        const headHeight = data.table.head[0].height;
        doc.setDrawColor(0); // Set the border color to black
        doc.setLineWidth(0.2); // Set the border line width

        // Draw borders for each column
        let currentX = startX;
        columnWidths.forEach((width) => {
            doc.rect(currentX, startY, width, rowHeight + headHeight);
            currentX += width;
        });
    };


    const dispatchModalOpen = (id) => {
        setDispatchModal(true);
        setBookingId(id);
        setDispatch({
            courier: "All Aces",
            dispatchBoxesData: [{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }],
            tracker: "",
            bookingId: id,
            log_status: "dispatched",
            log_by_id: user.data._id,
            notes: "",
            dispatched_by:"Lily Rose Winters",
            other_dispatched_by:""
        });
        setBoxes([{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }]);
        setSubmitFlag(0);
        setSelectedDispatchedByValue("");
        setOtherDispatchedFlag(false);
        setSelectedDispatchedByOptions([{label: "Lily Rose Winters", value: "Lily Rose Winters" }]);
        setSelectedOption([{ label: "All Aces", value: "All Aces" }]);
    };

    const dispatchModalClose = () => {
        setDispatchModal(false);
        setSubmitFlag(0);
        setSelectedDispatchedByValue("");
        setOtherDispatchedFlag(false);
        setError({});
    };

    const detailsModalOpen = (item) => {
        setDetailsModal(true);
        setDetailsModalResult(item);
    };

    const detailsModalClose = () => {
        setDetailsModal(false);
    };

    const handleCourier = (val) => {
        let value = val.value;
        dispatch.courier = value;
        setDispatch(dispatch);
        setSelectedOption(val);
    };

    const handleDispatchedBy = (val) => {
        let value = val.value;
        dispatch.dispatched_by = value;
        dispatch.other_dispatched_by = "";
        setDispatch(dispatch);
        setOtherDispatchedFlag(false);

        setSelectedDispatchedByValue(value);
        setSelectedDispatchedByOptions(val);
    };

    const handleBoxType = (val, index) => {
        var  boxeses  = [...boxes];
        boxeses[index].box_type = val.value;
        dispatch.dispatchBoxesData = boxeses;
        setBoxes(boxeses);
        setDispatch(dispatch);
        setSelectedNumOfBox(val);
    };

    const handleBoxNumber = (val, index) => {
        var  boxeses  = [...boxes];
        
        if (val != "" && val < 1) {
            val = "";
        }

        boxeses[index].number_of_box = val;
        boxeses[index].numberOfBoxFlag = false;
        dispatch.dispatchBoxesData = boxeses;
        setBoxes(boxeses);
        setDispatch(dispatch);
    };

    const handleOtherDispatchedName = (val) => {
        const dispatchData = {...dispatch};
        dispatchData.other_dispatched_by = val;
        setDispatch(dispatchData);
        setOtherDispatchedFlag(false);
    };

    const addMoreBox = () => {
        var  boxeses  = [...boxes];
        boxeses.push({ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false });
        dispatch.dispatchBoxesData = boxeses;
        setBoxes(boxeses);
        setDispatch(dispatch);
    };

    const handleBoxRemove = (ind) => {
        var  boxeses  = [...boxes];
        boxeses.splice(ind, 1);
        dispatch.dispatchBoxesData = boxeses;
        setBoxes(boxeses);
        setDispatch(dispatch);
    };

    const handleTrackerChange = (e) => {
        const { value } = e.target;
        const dispatchData = {...dispatch};
        dispatchData.tracker = value;
        setDispatch(dispatchData);
    };

    const handleNotesChange = (e) => {
        const { value } = e.target;
        const dispatchData = {...dispatch};
        dispatchData.notes = value;
        setDispatch(dispatchData);
    };

    const handleDispatch = async () => {
        setSubmitFlag(1);
        setOtherDispatchedFlag(true);

        var flag = 0;

        boxes.map(item => {
            if (item.box_type == "" || item.number_of_box == "") {
                flag = 1;
            }
            if (item.number_of_box == "") {
                item.numberOfBoxFlag = true;
            }
        });

        if (
            dispatch.courier == "" ||
            dispatch.tracker == ""
        ) {
            setLoading(true);
            try {
                const res = await bookingService.saveDispatchs(dispatch);
                if(res?.status === 200){
                    setDispatchModal(false);
                    getList(filterDeliveryFromDate,filterDeliveryToDate);
                }
                console.log(bookingId);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            } finally {
                setLoading(false);
            }

        } else if (flag == 0) {
            setLoading(true);
            try {
                const res = await bookingService.saveDispatchs(dispatch);
                console.log("res",res);
                if(res?.status === 200){
                    setDispatchModal(false);
                    getList(filterDeliveryFromDate,filterDeliveryToDate);
                }
                setLoading(false);
            } catch (error) {
                console.error("Error: ", error);
                setError(error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSearchDeliveryFromDate = (val) => {
        setActivePage(1);
        setSearchDeliveryFromDate(val != null ? new Date(val) : "");
        setfilterDeliveryFromDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setListRefresh(true);
    };

    const handleSearchDeliveryToDate = (val) => {
        setActivePage(1);
        setSearchDeliveryToDate(val != null ? new Date(val) : "");
        setfilterDeliveryToDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setListRefresh(true);
    };

    // const handleclearToDate = event => {
    //     if (event.key === "Backspace") {
    //         setSearchDeliveryToDate("");
    //         setfilterDeliveryToDate("");
    //         setListRefresh(true);
    //     }
    // };

    // const handleclearFromDate = event => {
    //     if (event.key === "Backspace") {
    //         setSearchDeliveryFromDate("");
    //         setfilterDeliveryFromDate("");
    //         setListRefresh(true);
    //     }
    // };

    const handleExportClick = async () => {
        const workbook = new excelJs.Workbook();
        const ws = workbook.addWorksheet("Dispatch Worksheet");
        
        let dateInfo = "";
        let dayActiveString = dayActive === "past" ? "past booking " : dayActive + " ";
        
        //let dateInfo = dayActive === "past" ? "from "+ moment(filterDeliveryFromDate).format("DD/MM/YYYY") + " to "+ moment(filterDeliveryToDate).format("DD/MM/YYYY"): moment(filterDeliveryFromDate).format("DD/MM/YYYY");
        
        if(dayActive === "past"){
            let dateTo = "";
            let dateFrom = "";

            if(filterDeliveryFromDate != ""){
                dateFrom = "from "+ moment(filterDeliveryFromDate).format("DD/MM/YYYY")+" ";
            }else{
                dateFrom = "";
            }
            
            if(filterDeliveryToDate != ""){
                dateTo = "to "+ moment(filterDeliveryToDate).format("DD/MM/YYYY")+" ";
            }else{
                dateTo = "";
            }

            dateInfo = dateFrom + dateTo;

        }else{
            dateInfo = moment(filterDeliveryFromDate).format("DD/MM/YYYY");
        }

        let headingContent =  "Dispatch "+ dayActiveString + dateInfo;
        // Add a header row with a merged cell for the heading
        const headingRow = ws.addRow([headingContent]);
        headingRow.getCell(1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "CCC0DA" } // Background color (e.g., yellow)
        };
    
        headingRow.eachCell((cell) => {
            cell.font = {
                bold: true,
                size: 13,
            };
        });
    
        ws.mergeCells(dayActive === "past" ? "A1:D1" : "A1:B1"); // Merge cells for the heading
        
        const header1 = [
            "BOOKING",
            "HOSPITAL NAME",
            "SET",
            "CHECKED",
            "BOXED",
        ];
        const header1Row = ws.addRow(header1);
        header1Row.eachCell((cell) => {
            cell.font = {
                bold: true,
                size: 11,
            };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "CCC0DA" } // Background color (e.g., yellow)
            };
            cell.border = {
                top: {style:"thin"},
                left: {style:"thin"},
                bottom: {style:"thin"},
                right: {style:"thin"}
            };
        });
    
        if (bookingList?.length) {
            var kitset = "";
            bookingList && bookingList.map(item => {
                kitset = "";
                item.bookingKitSetId &&
                    item.bookingKitSetId.map(kset => {
                        if (kitset != "") {
                            kitset += "\n" + kset.setId[0].set_name;
                        } else {
                            kitset += kset.setId[0].set_name;
                        }
                });

                const rowData = [
                    item.bookings.po_number,
                    item.hospitalId.name,
                    kitset,
                    "",
                    ""
                ];
                let row = ws.addRow(rowData);
                row.height = item?.bookingKitSetId?.length ? item.bookingKitSetId.length * 12 : 15;
                ws.getCell("C"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
            });
        }
        //ws.columns.forEach((col) => (col.width = 18));

        // Iterate through each column to calculate and set width
        ws.columns.forEach((column, columnIndex) => {
            let maxLength = 0;
            // Start from the third row (index 2) to skip the header row
            for (let rowIndex = 3; rowIndex <= ws.rowCount; rowIndex++) {
                const cellValue = ws.getCell(rowIndex, columnIndex + 1).value;
                if(columnIndex+1 === 3){
                    const array = cellValue.split("\n");
                    if(array?.length){
                        array.forEach(str => {
                            if (str.length > maxLength) {
                                maxLength = str.length;
                            }
                        });
                    }else{
                        if (cellValue && cellValue.toString().length > maxLength) {
                            maxLength = cellValue.toString().length;
                        }
                    }
                }else{
                    if (cellValue && cellValue.toString().length > maxLength) {
                        maxLength = cellValue.toString().length;
                    }
                }
            }
            // Set the width of the column based on the maximum length of its content
            column.width = maxLength < 20 ? 20 : maxLength + 2; // Add padding
        });

        ws.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
                row.eachCell((cell) => {
                    cell.border = {
                        top: { style: "thin", color: { argb: "000000" } },
                        left: { style: "thin", color: { argb: "000000" } },
                        bottom: { style: "thin", color: { argb: "000000" } },
                        right: { style: "thin", color: { argb: "000000" } },
                    };
                });
            }
        });

        const excelBlob = await workbook.xlsx.writeBuffer();
        const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        );
    
        const link = document.createElement("a");
        link.href = excelUrl;
        link.download = "booking-dispatch";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);  
    };

    const debouncedSearch = debounce((searchTerm) => {
        // Perform the search or API call with searchTerm
        getList(filterDeliveryFromDate,filterDeliveryToDate,searchTerm,1);
    }, 800);

    return (
        <div>
            <Navbar activePage="dashboard" />
            <main className="offset">
                <div className="container-fluid">
                    <div className="row pt-4">
                        <div className="col-lg-2 side-filters">
                            <span className="side-heading">Dashboard</span>
                            <DashboardSidebar
                                activePage="dispatch"
                                dispatchCount={totalItemsCount}
                            />
                        </div>

                        <div className="col-lg-10">
                            <div className="ground">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="sub-menu mb-0 mb-sm-3">
                                            <div
                                                className="btn-group"
                                                role="group"
                                                aria-label="Basic example">
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary " +
                                                        (dayActive ==
                                                            "today"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    onClick={() =>
                                                        !loading ? toDay() : ""
                                                    }>
                                                    {"Today"}
                                                </button>
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary mx-1 " +
                                                        (dayActive ==
                                                            "tomorrow"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    onClick={() =>
                                                        !loading ? nextDays() : ""
                                                    }>
                                                    {"Tomorrow"}
                                                </button>
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary " +
                                                        (dayActive ==
                                                            "past"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    onClick={() =>
                                                        !loading ? pastBookings() : ""
                                                    }>
                                                    {"Past Bookings"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <form className="form-search my-3 my-sm-0">
                                            <Input
                                                className="form-control mr-sm-2"
                                                type="text"
                                                placeholder="Search.."
                                                value={search}
                                                onChange={e =>
                                                    handleSearch(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </form>
                                    </div>

                                    <div className={dayActive === "past" ? "col-lg-4 col-sm-6" : "col-lg-4 col-sm-6 text-right"}>
                                        {/* <div className="d-flex justify-content-end" style={{gap:"10px"}}> */}  
                                        {/* icon code required */}

                                        <div className={dayActive === "past" ? "label_adjust lable-field-position" : ""}>
                                        {dayActive === "past" ?
                                            <label className="small mb-0">
                                                Delivery Date{" "}
                                            </label> : ""
                                        }

                                        {dayActive === "past" ? 
                                            <div className="row no-gutters mb-2">
                                                <div className="col-6">
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        placeholderText="From"
                                                        selected={searchDeliveryFromDate}
                                                        onChange={(e) =>handleSearchDeliveryFromDate(e)}
                                                        //onKeyDown={handleclearFromDate}
                                                        className="form-control"
                                                        disabled={!loading ? false : true}
                                                        customInput={
                                                            <MaskedInput
                                                                pipe={autoCorrectedDatePipe}
                                                                mask={[
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                ]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                                showMask={false}
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="col-6">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            showClearButton={false}
                                                            placeholderText="To"
                                                            selected={searchDeliveryToDate}
                                                            onChange={(e) => handleSearchDeliveryToDate(e)}
                                                            //onKeyDown={handleclearToDate}
                                                            className="form-control"
                                                            disabled={!loading ? false : true}
                                                            customInput={
                                                                <MaskedInput
                                                                    pipe={autoCorrectedDatePipe}
                                                                    mask={[
                                                                        /\d/,
                                                                        /\d/,
                                                                        "/",
                                                                        /\d/,
                                                                        /\d/,
                                                                        "/",
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                    ]}
                                                                    keepCharPositions={true}
                                                                    guide={true}
                                                                    showMask={false}
                                                                />
                                                            }
                                                        />
                                                </div>
                                            </div> : 
                                        ""}

                                        <div style={{float:"right"}}>
                                            <button type="button" className="btn btn-default mr-md-2 my-1"
                                                onClick={() => bookingList?.length ? handleGeneratePdf() : ""}
                                            > Generate Dispatch Pdf</button>

                                            <button type="button" className="btn btn-default add_option" onClick={() => handleExportClick()}> Print </button>
                                        </div>

                                       

                                        {/* <div>
                                            <button type="button" className="btn btn-default add_option"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Generate Dispatch Pdf"
                                                data-original-title="Generate Dispatch Pdf"
                                                onClick={() => bookingList?.length ? handleGeneratePdf() : ""}
                                            >
                                                <i className="fa fa-file-pdf-o" style={{color:"#444fe5"}}></i>
                                            </button>                                            
                                        </div> */}  
                                        {/* icon code required */}
                                       

                                        {/* <div> */} 
                                        {/* icon code required */}

                                        
                                        
                                        {/* <ExportSheet
                                            header={head}
                                            fileName={"booking-dispatch"}
                                            dataSource={dataXlsx}
                                            xlsx={XLSX}
                                            className="btn btn-default">
                                            <button className="btn btn-default add_option">
                                                Print
                                            </button> */}
                                            {/* <button className="btn btn-default add_print"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Print"
                                                data-original-title="Print"
                                            >
                                                <i className="fa fa-print" style={{color:"#444fe5"}}></i>
                                            </button> */}  
                                            {/* icon code required */}
                                        {/* </ExportSheet> */}
                                        {/* </div> */}
                                        {/* </div> */}  
                                        {/* icon code required */}
                                    </div>

                                    {/* <div className="col-md-3 col-sm-6 text-right">

                                </div> */}


                                    </div>
                                    </div>
                                <div className="table-scroll mt-4">
                                    <Table className="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        minWidth: "200px",
                                                    }}>
                                                    Booking #
                                                </th>
                                                <th scope="col">
                                                    Reps name{" "}
                                                </th>
                                                <th scope="col">
                                                    Hospital Name{" "}
                                                </th>
                                                <th scope="col">SET</th>
                                                <th scope="col">Date</th>
                                                <th
                                                    scope="col"
                                                    className="text-right"></th>
                                                <th
                                                    scope="col"
                                                    className="text-right"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookingList &&
                                                bookingList.map(item => (
                                                    <tr key={item._id}>
                                                        <td
                                                            width="200"
                                                            className="align-top">
                                                            <Link
                                                                to={
                                                                    "/admin/booking/details/" +
                                                                    item
                                                                        .bookings
                                                                        ._id
                                                                }>
                                                                <span>
                                                                    <strong>
                                                                        {" "}
                                                                        {
                                                                            item
                                                                                .bookings
                                                                                .booking_id
                                                                        }{" "}
                                                                    </strong>
                                                                </span>
                                                            </Link>
                                                        </td>
                                                        <td className="align-top">
                                                            <div className="">
                                                                {item
                                                                    .assigned_rep
                                                                    .first_name +
                                                                    " " +
                                                                    item
                                                                        .assigned_rep
                                                                        .last_name}
                                                            </div>
                                                        </td>
                                                        <td className="align-top">
                                                            <div className="">
                                                                {
                                                                    item
                                                                        .hospitalId
                                                                        .name
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="align-top">
                                                            <small>
                                                                {item.bookingKitSetId &&
                                                                    item.bookingKitSetId.map(
                                                                        (
                                                                            kset,
                                                                            ind,
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={
                                                                                    ind
                                                                                }>
                                                                                {/* <div><a href="javascript:;" onClick={()=>handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div> */}
                                                                                <div>
                                                                                    <span>
                                                                                        {
                                                                                            kset
                                                                                                .setId[0]
                                                                                                .set_name
                                                                                        }{" "}
                                                                                    </span>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        ),
                                                                    )}
                                                            </small>
                                                        </td>
                                                        <td className="align-top">
                                                            {dateFormat(
                                                                item
                                                                    .bookings
                                                                    .delivery_date,
                                                                "ddd,mmm dS",
                                                            )}{" "}
                                                            {
                                                                item
                                                                    .bookings
                                                                    .delivery_date_slot
                                                            }
                                                        </td>
                                                        <td className="text-right align-top">
                                                            <div className="d-flex">
                                                                {" "}
                                                                {item
                                                                    .bookings
                                                                    .status ==
                                                                    "booked" && (
                                                                        <a
                                                                            href="javascript:;"
                                                                            onClick={() =>
                                                                                dispatchModalOpen(
                                                                                    item
                                                                                        .bookings
                                                                                        ._id,
                                                                                )
                                                                            }
                                                                            className="btn btn-primary ml-sm-2 btn-sm">
                                                                            Dispatch
                                                                        </a>
                                                                    )}
                                                                {item
                                                                    .bookings
                                                                    .status ==
                                                                    "dispatch" && (
                                                                        <React.Fragment>
                                                                            <div><span className="capsule green-dim mb-2 mr-2">
                                                                                Dispatched
                                                                            </span></div>
                                                                            <a
                                                                                href="javascript:;"
                                                                                onClick={() =>
                                                                                    detailsModalOpen(
                                                                                        item.bookingdispatches,
                                                                                    )
                                                                                }
                                                                                className=" mb-2 ">
                                                                                Details
                                                                            </a>
                                                                        </React.Fragment>
                                                                    )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            {bookingList && bookingList.length == 0 && loading == true &&                                                    
                                                <div className="loading-state">
                                                    <div className="loading"></div>
                                                </div>                                               
                                            }

                                            {bookingList &&
                                                bookingList.length == 0 &&
                                                loading == false && (
                                                    <tr className="text-center">
                                                        <td colSpan="5">
                                                            No Record Found
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            {bookingList && bookingList.length > 0 && (
                                <div>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={
                                            totalItemsCount
                                        }
                                        pageRangeDisplayed={5}
                                        onChange={handlePageChange.bind(
                                            this,
                                        )}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center mb-5"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Prev"
                                    />
                                    <br className="d-block d-sm-none" /><br className="d-block d-sm-none" />
                                </div>
                            )}

                            <AddDispatch
                                bookingId={bookingId}
                                dispatchModal={dispatchModal}
                                dispatchModalClose={() => dispatchModalClose()}
                                boxes={boxes}
                                selectedOption={selectedOption}
                                handleCourier={(e) => handleCourier(e)}
                                options={options}
                                error={error}
                                submitFlag={submitFlag}
                                selectedDispatchedByOptions={selectedDispatchedByOptions}
                                handleDispatchedBy={(e) => handleDispatchedBy(e)}
                                dispatchedByOptions={dispatchedByOptions}
                                selectedDispatchedByValue={selectedDispatchedByValue}
                                dispatch={dispatch}
                                handleOtherDispatchedName={(value) =>handleOtherDispatchedName(value)}
                                otherDispatchedFlag={otherDispatchedFlag}
                                selectedNumOfBox={selectedNumOfBox}
                                handleBoxType={(e,index) => handleBoxType(e, index)}
                                boxOptions={boxOptions}
                                handleBoxNumber={(value,index) =>handleBoxNumber(value,index)}
                                handleBoxRemove={(index) => handleBoxRemove(index)}
                                addMoreBox={()=>addMoreBox()}
                                handleTrackerChange={(e)=>handleTrackerChange(e)}
                                handleNotesChange={(e)=>handleNotesChange(e)}
                                handleDispatch={()=>handleDispatch()}
                                loading={loading} 
                            />

                            {/* Dispatch Modal */}

                            <DispatchDetails
                                bookingId={bookingId}
                                detailsModal={detailsModal}
                                detailsModalResult={detailsModalResult}
                                detailsModalClose={()=>
                                    detailsModalClose()
                                }
                            />

                            {/* Dispatch Details Modal */}
                        </div>
                    </div>
                    {/*<!--/row-->*/}
                </div>
            </main>

            <Footer />
        </div>
    );
};
export default NewAdminBookingDispatch;

