import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input, Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DashboardSidebar from "./NewDashboardSidebar";
import dateFormat from "dateformat";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { EquipmentUsedReportService } from "../../../../services/equipmentUsedReport.services";
import moment from "moment";
import * as XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
// import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

const head = [
    { title: "Booking ID", dataIndex: "Booking_ID" },
    { title: "Hospital Name", dataIndex: "Name_of_Hospital" },
    { title: "Surgeon Name", dataIndex: "Name_of_Surgeon" },
    { title: "Operation Name", dataIndex: "Name_of_Operation" },
    { title: "Sets", dataIndex: "Name_of_Sets" },
    { title: "Operation Date", dataIndex: "Date_of_Operation" },
    { title: "Outcome Of The Case", dataIndex: "Case" },
    { title: "Notes", dataIndex: "Notes" },
];

const EquipmentUsedReport = () => {
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");
    const [data, setData] = useState([]);
    const [dataXlsx, setDataXlsx] = useState([]);
    const [search, setSearch] = useState("");
    const [searchFromDate, setSearchFromDate] = useState("");
    const [searchToDate, setSearchToDate] = useState("");
    const [filterDeliveryToDate, setfilterDeliveryToDate] = useState("");
    const [filterDeliveryFromDate, setfilterDeliveryFromDate] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState("");
    const [limit, setLimit] = useState(50);
    const [loading, setLoading] = useState(false);

    const handleSearch = (val) => {
        setSearch(val);
        setActivePage(1);
    };

    const handleSearchFromDate = (val) => {
        setSearchFromDate(val != null ? val : "");
        setfilterDeliveryFromDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setActivePage(1);
    };

    const handleclearToDate = event => {
        if (event.key === "Backspace") {
            setSearchToDate("");
            setfilterDeliveryToDate("");
        }
    };

    const handleclearFromDate = event => {
        if (event.key === "Backspace") {
            setSearchFromDate("");
            setfilterDeliveryFromDate("");
        }
    };

    const handleSearchToDate = (val) => {
        setSearchToDate(val != null ? val : "");
        setfilterDeliveryToDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setActivePage(1);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setLoading(true);
        getData();
    };

    const handlePageStates = (activePage, totalItemsCount, limit) => {
        if (totalItemsCount === undefined) {
            totalItemsCount = 0;
        }
        setActivePage(activePage);
        setTotalItemsCount(totalItemsCount);
        setLimit(limit);
    };

    const showXlsData = async () => {
        const newDataXlsx = data.map((item) => ({
            "Booking_ID": item.bookings.booking_id,
            "Name_of_Hospital": item.hospitalData[0].name,
            "Name_of_Surgeon":item.bookingSurgeonData[0].surgeonData[0].first_name + " " + item.bookingSurgeonData[0].surgeonData[0].last_name,
            "Name_of_Operation" : item.bookings.operation_name,
            "Name_of_Sets": item.bookingKitSetData ? item.bookingKitSetData.map((kit) => kit.setId[0].set_name).join(", ") : "",
            "Date_of_Operation": dateFormat(item.bookings.operation_date, "ddd,mmm dS"),
            "Case": item.bookingproductreviewsData[0] ? item.bookingproductreviewsData[0].review : "",
            "Notes": item.bookingproductreviewsData[0] ? item.bookingproductreviewsData[0].description : "",
        }));
        setDataXlsx(newDataXlsx);
    };

    const getData = async () => {
        setData([]);
        setLoading(true);
        try {
            const res = await EquipmentUsedReportService.equipmentUsedReportApi({
                "search": search,
                "from_date_filter": filterDeliveryFromDate,
                "to_date_filter": filterDeliveryToDate,
                "page": activePage,
                "limit": limit,
                "status": ["booked", "dispatch", "pending", "return", "close"],
            });
            setData(res.data);
            setTotalItemsCount(res.total_count);
            setLoading(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        showXlsData();
    }, [data]);

    useEffect(() => {
        handlePageStates(activePage, totalItemsCount, limit);
    }, [activePage, totalItemsCount, limit]);

    useEffect(() => {
        getData();
    }, [search, searchFromDate, searchToDate, activePage, limit]);
    
    const handleToggleText = (index, fullText) => {
        setData((data) => {
            const newData = [...data];
            newData[index].bookingproductreviewsData[0].showMore = !newData[index].bookingproductreviewsData[0].showMore;
            const element = document.getElementById(`notes-${index}`);
            element.textContent = newData[index].bookingproductreviewsData[0].showMore ? fullText : truncateText(fullText, 25);
            return newData;
        });
    };

    const truncateText = (text, maxLength) => {
        if (text && text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };

    return (
        <div>
            <Navbar activePage="dashboard" />
            <main className="offset">
                <div className="container-fluid">
                    <div className="row pt-4">
                        <div className="col-lg-2 side-filters">
                            <span className="side-heading">Dashboard</span>
                            <DashboardSidebar activePage="equipment" />
                        </div>

                        <div className="col-lg-10">
                            <div className="ground">
                                <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                        <form className="form-search">
                                            <Input
                                                className="form-control mr-sm-2"
                                                type="text"
                                                placeholder="Search.."
                                                value={search}
                                                onChange={(e) => handleSearch(e.target.value)}
                                            />
                                        </form>
                                    </div>
                                    <div className="col-md-8 col-sm-6 d-flex justify-content-between align-items-center">
                                        <div className="label_adjust">
                                            <label className="small mb-0">Date </label>
                                            <div className="row no-gutters">
                                                <div className="col">

                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        placeholderText="From"
                                                        selected={searchFromDate}
                                                        onChange={(date) => handleSearchFromDate(date)}
                                                        onKeyDown={handleclearFromDate}
                                                        className="form-control"
                                                        customInput={
                                                            <MaskedInput
                                                                pipe={autoCorrectedDatePipe}
                                                                mask={[
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                ]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                                showMask={false}
                                                            />
                                                        }
                                                    />
                                                </div>
                                                <div className="col">
                                                    <div className="mx-1">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            showClearButton={false}
                                                            placeholderText="To"
                                                            selected={searchToDate}
                                                            onChange={e => handleSearchToDate(e,)}
                                                            onKeyDown={handleclearToDate}
                                                            className="form-control"
                                                            customInput={
                                                                <MaskedInput
                                                                    pipe={
                                                                        autoCorrectedDatePipe
                                                                    }
                                                                    mask={[
                                                                        /\d/,
                                                                        /\d/,
                                                                        "/",
                                                                        /\d/,
                                                                        /\d/,
                                                                        "/",
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                    ]}
                                                                    keepCharPositions={true}
                                                                    guide={true}
                                                                    showMask={false}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            <ExportSheet
                                                header={head}
                                                fileName={"equipment-used"}
                                                dataSource={dataXlsx}
                                                xlsx={XLSX}
                                                className="btn btn-default add_option"
                                            >
                                                <button className="btn btn-default add_option">Print</button>
                                            </ExportSheet>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-scroll mt-4">
                                    <Table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" >Booking #</th>
                                                <th scope="col" style={{ minWidth: "200px" }}>Hospital Name</th>
                                                <th scope="col" style={{ minWidth: "200px" }} >Surgeon Name</th>
                                                <th scope="col" >Operation Name</th>
                                                <th scope="col" style={{ minWidth: "200px" }}>Sets</th>
                                                <th scope="col">Operation Date</th>
                                                <th scope="col">Outcome Of The Case</th>
                                                <th scope="col">Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!loading && data && data.map((item, inx) => (
                                                <tr key={inx}>
                                                    <td width="200" data-th="Booking #" className="align-top">
                                                        <div className="">
                                                            <Link to={"/admin/booking/details/" + item.bookings._id}>
                                                                <span>
                                                                    <strong>
                                                                        {" "}
                                                                        {
                                                                            item
                                                                                .bookings
                                                                                .booking_id
                                                                        }{" "}
                                                                    </strong>
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td data-th="Hospital Name" className="align-top">
                                                        <div className="">
                                                            {item.hospitalData && item.hospitalData.map((hospital, ind) => (
                                                                <React.Fragment key={ind}>
                                                                    <div><span>{hospital.name} </span></div>
                                                                </React.Fragment>
                                                            ))}

                                                        </div>
                                                    </td>
                                                    <td data-th="Surgeon Name" className="align-top">
                                                        <div>
                                                            {item.bookingSurgeonData && item.bookingSurgeonData.map((surgeon, ind) => (
                                                                <React.Fragment key={ind}>
                                                                    <div><span>{surgeon.surgeonData[0].first_name + " " + surgeon.surgeonData[0].last_name} </span></div>
                                                                </React.Fragment>
                                                            ))}

                                                        </div>
                                                    </td>
                                                    <td data-th="Operation Name" className="align-top">
                                                        <div>
                                                            {item.bookings.operation_name}
                                                        </div>
                                                    </td>
                                                    <td data-th="Sets" className="align-top">
                                                        <div>
                                                            {item.bookingKitSetData && item.bookingKitSetData.map((kit, ind) => (
                                                                <React.Fragment key={ind}>
                                                                    <div><span>{kit.setId[0].set_name} </span></div>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </td>
                                                    <td data-th="Operation Date" className="align-top">
                                                        <div>
                                                            {dateFormat(item.bookings.operation_date, "ddd,mmm dS")}
                                                        </div>
                                                    </td>
                                                    <td data-th="Outcome Of The Case" className="align-top">
                                                        <div>
                                                            {item.bookingproductreviewsData[0] ? item.bookingproductreviewsData[0].review : ""}
                                                        </div>
                                                    </td>
                                                    <td data-th="Notes" className="align-top">
                                                        <div id={`notes-${inx}`} className={item.bookingproductreviewsData[0].showMore ? "" : "truncate"}>
                                                            {item.bookingproductreviewsData[0] ? truncateText(item.bookingproductreviewsData[0].description, 25) : ""}
                                                        </div>
                                                        {item.bookingproductreviewsData[0] && item.bookingproductreviewsData[0].description && item.bookingproductreviewsData[0].description.length > 25 && (
                                                            <Link onClick={() => handleToggleText(inx, item.bookingproductreviewsData[0].description)}>
                                                                {item.bookingproductreviewsData[0].showMore ? "Show Less" : "Read More"}
                                                            </Link>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}

                                            {data && data.length == 0 && loading == true &&                                                    
                                                <div className="loading-state">
                                                    <div className="loading"></div>
                                                </div>                                               
                                            }

                                            {data && data.length == 0 && loading == false &&
                                                <tr className="text-center">
                                                    <td colSpan="8">No Record Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>

                                    {data && data.length > 0 && loading == false && (
                                        <div>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={limit}
                                            totalItemsCount={totalItemsCount}
                                            pageRangeDisplayed={5}
                                            onChange={(pageNumber) => handlePageChange(pageNumber)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center mb-5"
                                            activeLinkClass="active"
                                            nextPageText="Next"
                                            prevPageText="Prev"
                                        />
                                        <br className="d-block d-sm-none"/><br className="d-block d-sm-none"/>
                                        </div>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};
export default EquipmentUsedReport;

