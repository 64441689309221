import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import KitNavbar from "./KitNavbar";
import { Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import { setActions } from "../../../../actions";
import { ReactComponent  as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setService } from "../../../../services";

const statusOptions = [
    { value: "all", label: "All" },
    { value: "missing_stock", label: "Missing Stocks" },
    { value: "quarantine", label: "Quarantine" },
];

class Kits extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            deleteModal:false,
            id: "",
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 10,
            status: "all",
            selectedFilter: { value: "all", label: "All" },
            quarantine_check: false,
            missing_stock_check: false
        };

        // this.handleUsernameChange = this.handleUsernameChange.bind(this);
        // this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.deleteModalOpen = this.deleteModalOpen.bind(this);
        this.deleteModalClose = this.deleteModalClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
        this.setMissingStock = this.setMissingStock.bind(this);
        this.setQuarantine = this.setQuarantine.bind(this);
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            { 
                activePage: pageNumber }
            , () => {
                this.getSetsList();
            }
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {
        this.setState(
            {
                activePage: activePage,
                totalItemsCount: totalItemsCount,
                limit: limit
            }
        );
    }

    getSetsList() {
        this.props.dispatch(setActions.getSetsList({search: this.state.search, page: this.state.activePage, limit: this.state.limit,filter:this.state.status}));
    }

    handleSearch(val) {
        this.setState({
            search: val,
            activePage:1
        },()=>{this.getSetsList();});
        
    }

    deleteModalOpen() {
        this.setState({ deleteModal: true});
    }

    deleteModalClose() {
        this.setState({ 
            deleteModal: false,
            id: ""
        });
    }

    handleDelete(e, id) {
        e.preventDefault();
        this.setState({ 
            id: id,
            error: false
        });
        this.deleteModalOpen();
    }

    doDelete() {
        this.setState({ 
            error: true
        });
        this.props.dispatch(setActions.delete({id: this.state.id}));
    }

    setMissingStock(val,item) {
        this.setState({
            missing_stock_check: val
        });

        let payloadMisstock = {
            id: item._id,
            quarantine_check: item.quarantine_check ? true : false,
            missing_stock_check:val,
        };

        try{
           setService.updateMissingAndQuarantineStock(payloadMisstock).then((res)=>{
            if(res.status === 200){
                this.getSetsList();
            }
           }).catch((error)=>{
            console.log(error);
           });   
        }catch(error){
            console.log(error);
        }

        
    }

    setQuarantine(val,item) {
        this.setState({
            quarantine_check: val
        });

        let payloadQuarantine = {
            id: item._id,
            quarantine_check: val,
            missing_stock_check:item.missing_stock_check ? true : false,
        };

        try{
            setService.updateMissingAndQuarantineStock(payloadQuarantine).then((res)=>{
             if(res.status === 200){
                this.getSetsList();
             }
            }).catch((error)=>{
             console.log(error);
            });   
         }catch(error){
             console.log(error);
         }

        //this.props.dispatch(setActions.updateSet(payload));
    }
    
    // handleSubmit(e) {
    //     console.log("dsads");
    //     e.preventDefault();
    //     this.setState({ submitted: true });
    //     const { username, password } = this.state;
    //     const { dispatch } = this.props;
    //     console.log(username,password);
        
    //     //if (username && password) {
    //     dispatch(authActions.login(username, password));
    //     //}
    // }

    // handleUsernameChange(e) {
    //     this.setState({ username: e.target.value });
    // }

    // handlePasswordChange(e) {
    //     this.setState({ password: e.target.value });
    // }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
      
        if(nextProps.refreshList == true || nextProps.redirect == true)
        {
            this.setState({
                activePage:this.state.activePage
            },()=>{this.getSetsList();});
        }

        if(nextProps.activePage != "" && nextProps.totalItemsCount != "" && nextProps.limit != ""){
            this.handlePageStates(nextProps.activePage, nextProps.totalItemsCount, this.state.limit);
        }

        // if(nextProps.modal == false)
        // {
        //     this.addNewModalClose();
        // }

        // if(nextProps.editModal == true)
        // {
        //     this.setEditProduct(nextProps.product);
        // }

        // if(nextProps.editModal == false)
        // {
        //     this.editModalClose();
        // }

        if(nextProps.deleteModal == false)
        {
            this.deleteModalClose();
        } 
        
        if (nextProps.error !== "") {
            console.log("nextProps.error ",nextProps.error );
            if(nextProps.error !== undefined){
                if(nextProps.error.description){
                    toast.error(nextProps.error.description[0], {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }

                if(nextProps.error.kitID){
                    toast.error(nextProps.error.kitID[0], {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }

                if(nextProps.error.set_name){
                    toast.error(nextProps.error.set_name[0], {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }  
        }
        
    }

    handleStatus(e) {
        this.setState(
            {
                status: e.value,
                selectedFilter:e,
                activePage:1
            },
            () => {
                this.getSetsList();
            }
        );
    }

    componentDidMount(){
        this.getSetsList();
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if(this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        const { setsList, loading , error} = this.props;
        //const { username, password} = this.state;
        return (
            <div>
                 <ToastContainer />
                <Navbar activePage="kits"/>
                <div className="container">    
                    <div className="pt-4 row align-items-center">
                        <div className="col-md-3">
                            <div className="sub-menu">
                                <KitNavbar activePage="sets" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <form className="form-search">
                                <Input 
                                    className="form-control mr-sm-2" 
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.search}
                                    onChange={(e) => this.handleSearch(e.target.value)}
                                />            
                            </form>
                        </div>
                        <div className="col-md-3">
                            <form className="form-search my-1">
                                <Select
                                    defaultValue={this.state.selectedFilter}
                                    onChange={this.handleStatus}
                                    options={statusOptions}
                                />
                            </form>
                        </div>  
                        <div className="col-md-2 text-right">
                            <Link to="/admin/sets" className="btn btn-primary add_option"><i className="fa fa-plus"></i> <span>Add New</span></Link>
                        </div>
                    </div>
                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>              
                                    <th scope="col">Set Name</th>
                                    <th scope="col" className="" style={{minWidth:"120px"}}>Kit</th>  
                                    <th scope="col">Missing Stock</th>
                                    <th scope="col">Quarantine</th>      
                                    <th scope="col" className="text-right" width="100">Actions</th>                
                                </tr>
                            </thead>
                            <tbody>
                                { setsList && setsList.map((set) => (
                                    <tr key={set._id}>           
                                        <td>
                                            <div className="">                   
                                                {/* <span><a href="#"><strong>{set.set_name}</strong> <br /><small>{set.setProductModules.length} Items</small></a></span> */}
                                                <span><a href="#"><strong>{set.sets.set_name}</strong></a></span>
                                            </div>
                                        </td>
                                        <td className="">
                                            {set.sets.kitID[0].name}
                                        </td>

                                        <td style={{minHeight:"44px"}}>
                                            {/* {set?.sets?.missing_stock_check ? <strong>{"Checked"}</strong>: "Unchecked"} */}

                                            <div className="">
                                                <Input className="form-check-input mx-1" type="checkbox"
                                                    checked={set?.sets?.missing_stock_check ? true : false}
                                                    value={set?.sets?.missing_stock_check ? true : false}
                                                    onChange={(e) => this.setMissingStock(e.target.checked,set?.sets)}
                                                />
                                            </div>
                                        </td>

                                        <td style={{minHeight:"44px"}}>
                                            {/* {set?.sets?.quarantine_check ? <strong>{"Checked"}</strong> : "Unchecked"} */}

                                            <div className="">
                                                <Input className="form-check-input mx-1" type="checkbox"  
                                                    checked={set?.sets?.quarantine_check ? true : false}
                                                    value={set?.sets?.quarantine_check ? true : false}
                                                    onChange={(e) => this.setQuarantine(e.target.checked,set?.sets)}
                                                />
                                            </div>

                                        </td>

                                        <td className="text-right">
                                            <div className="action-area dropdown">
                                                <a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="dots">...</span>
                                                </a>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">                       
                                                    <Link className="dropdown-item" to={"/admin/edit-sets/"+set.sets._id}>Edit</Link>
                                                    <a className="dropdown-item" onClick={(e) => this.handleDelete(e, set.sets._id)}  href="#">Delete</a>                    
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                { setsList && setsList.length == 0 && loading == false && 
                                    <tr className="text-center">
                                        <td colSpan="5">No Record Found</td>
                                    </tr>
                                }

                                { setsList && setsList.length == 0 && loading == true &&  
                                    <tr className="text-center">
                                        <td colSpan="5">
                                            <div className="loading-state-new">
                                                <div className="loading"></div>
                                            </div>                                               
                                        </td>
                                    </tr>                                                                
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <div className="mt-4 text-center">
                    <div className="justify-content-center d-flex"> */}
                {setsList && setsList.length >0 &&
                <div>    
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center mb-5"
                        activeLinkClass="active"
                        nextPageText="Next"
                        prevPageText="Prev"
                    />
                </div>
                }
                {/* </div>
                </div> */}
                
                <Modal size="md" backdrop="static" isOpen={this.state.deleteModal} toggle={() => this.deleteModalClose()}>
                    <ModalHeader className="" toggle={() => this.deleteModalClose()}>
                        Delete Set
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">Please confirm if you want to delete this set?</div>
                        {(this.state.error && error) ? <span className="text-danger">{error.common ? error.common : ""}</span> : ""}
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.deleteModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false && 
                                <button className="btn btn-primary" onClick={this.doDelete}>Ok</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }
                        </div>
                    </ModalFooter>
                </Modal>

                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setsList, loading, deleteModal, refreshList, error} = state.rootReducer.sets;
    const { activePage, totalItemsCount, limit,redirect} = state.rootReducer.sets;

    return {
        setsList,
        loading,
        deleteModal,
        refreshList,
        activePage,
        totalItemsCount,
        limit,
        error,
        redirect
    };
}

export default connect(mapStateToProps)(Kits);