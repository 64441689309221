// import axios from "axios";

// export const EquipmentUsedReportService = {
//     equipmentUsedReportApi
// };

// async function equipmentUsedReportApi(search) {
//     try {
//         const response = await axios.post(
//             process.env.REACT_APP_APIURL + "products/equipmentUsedReportApi",
//             JSON.stringify(search),
//             {
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//             }
//         );
//         console.log("equipmentUsedReportApi Response", response.data);
//         return response.data;
//     } catch (error) {
//         console.error("Error in equipmentUsedReportApi:", error);
//         throw error;
//     }
// }
import axios from "axios";
import { authHeader,check419 } from "../helpers";

export const EquipmentUsedReportService = {
    equipmentUsedReportApi
};

function equipmentUsedReportApi(search) {
    return axios.post(
        process.env.REACT_APP_APIURL + "products/equipmentUsedReportApi",
        JSON.stringify(search),
        {
            headers: {
                ...authHeader(),
                "Content-Type": "application/json",
            },
        }
    )
    .then(response => {
        console.log("equipmentUsedReportApi Response", response.data);
        return response.data;
    })
    .catch(error => {
        if(error?.response?.status === 419){
            check419();
        }else{
            console.error("Error in equipmentUsedReportApi:", error);
            throw error;
        }
    });
}
